<template>

  <v-card

  >

    <v-toolbar
      color="primary"
      dark

    >

      <v-toolbar-title>{{ $t("Contacts") }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-icon>mdi-account-search</v-icon>

    </v-toolbar>

    <v-data-table
      v-bind:loading="loading"

      loading-text="Loading... Please wait"
      aria-placeholder="red"
      :headers="headers"
      :items="myData"
      item-key="name"
      height="250"
      :search="search"

    >

      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Search"
          background-color=""
          height="20"
        ></v-text-field>

      </template>

    </v-data-table>

  </v-card>

</template>

<script>

import firebase from 'firebase/app'

export default {
  name: 'Searchboxkeith',
  data () {
    return {
      search: '',
      email: '',
      myData: [{
        name: 'Arlina Whyteman',
        email: 'awhytemanw@nytimes.com',
        code: '02WJ4JZ'
      }, {
        name: 'Madelaine Reston',
        email: 'mrestonx@nyu.edu',
        code: '0NCS4ZZ'
      }, {
        name: 'Carri Triggel',
        email: 'ctriggely@vk.com',
        code: 'BP0NZZZ'
      }],
      loading: false,
      db: firebase.database()
    }
  },
  computed: {
    headers () {
      return [
        {
          text: 'First',
          align: 'start',
          value: 'first_name'
        },
        {
          text: 'Last',
          align: 'start',
          value: 'last_name'
        },
        {
          text: 'Email',
          value: 'email'
        },
        {
          text: 'Role',
          value: 'role'
        }

      ]
    }

  },
  methods: {

    getData () {
      fetch(
        'http://localhost:3001/senddatakeith',
        {
          method: 'get'
        }
      )
        .then((response) => {
          return response.json()
        })
        .then((jsonData) => {
          this.myData = jsonData
        })
      // https://opentdb.com/api.php?amount=${this.numberOfQuestions}&category=${this.category}&difficulty=${this.difficulty}&type=multiple
    },

    getFBData () {
      this.loading = true
      const starCountRef = this.db.ref('students')
      starCountRef.on('value', (snapshot) => {
        const data = snapshot.val()
        const myData = Object.keys(data).map(key => {
          return data[key]
        })
        this.myData = myData
      })
      this.loading = false
    }

  },
  mounted: function () {
    this.getFBData()
  }

}
</script>

<style scoped>

</style>
