<template>
  <v-card

  >
    <v-toolbar
      color="primary"
      dark
    >
      <v-toolbar-title>{{ $t("Notes") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn to="notes" icon>
        <v-icon>mdi-note</v-icon>
      </v-btn>

    </v-toolbar>

    <v-list height="400" class="overflow-auto">
      <v-list-item-group v-model="model">

        <v-list-item v-if="!items">You have no notes : )</v-list-item>

        <div v-for="(notes, itemsIndex) in items" :key="itemsIndex">

          <v-list-item-subtitle class="text-end mr-2">{{ notes.title }}</v-list-item-subtitle>
          <v-list-item to="notes"
            v-bind:class="note.note_type"
            v-for="(note, i) in notes.notes" :key="i"
          >

            <v-list-item-icon>
              <v-icon>mdi-note-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>

              <v-list-item-title v-text="note.text +'...'"></v-list-item-title>
            </v-list-item-content>

          </v-list-item>

        </div>

      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
import { db } from '@/plugins/dbOperations'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'NotesListKeith',

  data: () => ({
    items: [
      {
        icon: 'mdi-inbox',
        text: 'Inbox'
      },
      {
        icon: 'mdi-star',
        text: 'Star'
      },
      {
        icon: 'mdi-exclamation',
        text: 'Send'
      },
      {
        icon: 'mdi-exclamation',
        text: 'Drafts'
      },
      {
        icon: 'mdi-exclamation',
        text: 'Send'
      },
      {
        icon: 'mdi-exclamation',
        text: 'Drafts'
      },
      {
        icon: 'mdi-exclamation',
        text: 'Send'
      },
      {
        icon: 'mdi-exclamation',
        text: 'Drafts'
      },
      {
        icon: 'mdi-exclamation',
        text: 'Send'
      },
      {
        icon: 'mdi-exclamation',
        text: 'Drafts'
      }
    ],
    model: 1
  }),
  methods: {
    ...mapMutations(['saveUser']),
    ...mapActions(['']),
    ...mapGetters(['getUser']),

    async getNotes () {
      let myDbData
      await db.ref('students').on('value', (snapshot) => {
        snapshot.forEach((childSnapshot) => {
          const childData = childSnapshot.val()
          if (childData.student_id === this.getUser().student_id) {
            myDbData = childSnapshot.val()
            this.items = myDbData.notes
            return myDbData
          }
        })
      })
      return myDbData
    }

  },

  mounted: function () {
    this.getNotes()
  }

}
</script>

<style scoped>

.positive {
  background: #CCFF90;

}

.neutral {
  background: #E0E0E0;
}

.improvement {
  background: #FFD54F;
}

</style>
