<template>

  <v-card>

    <v-toolbar
      color="primary"
      dark
    >

      <v-toolbar-title>{{ $t("Good Afternoon") }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-icon>mdi-face</v-icon>

    </v-toolbar>

    <v-card-title>{{ this.getUser().first_name }} {{ this.getUser().last_name }}</v-card-title>
    <v-card-subtitle> {{ dateDateNow }}</v-card-subtitle>

    <v-col md="12" class="text-h4 ">
      {{ timeDateNow }}
    </v-col>

  </v-card>

</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'HelloKeith',
  data () {
    return {
      timeNow: undefined,
      dateNow: undefined
    }
  },
  watch: {},
  methods: {
    ...mapMutations(['saveUser']),
    ...mapActions(['']),
    ...mapGetters(['getUser']),

    updateTime () {
      this.timeNow = Date.now()
      this.dateNow = this.timeNow
      setTimeout(() => {
        this.updateTime()
      }, 1000)
    }
  },

  computed: {
    timeDateNow () {
      return moment(this.timeNow, 'x').format('HH:MM:ss')
    },
    dateDateNow () {
      return moment(this.timeNow, 'x').format('ddd, DD MMM YYYY')
    }
  },

  mounted () {
    this.updateTime()
  }
}
</script>

<style scoped>

</style>
