import Vue from 'vue'
import VueRouter from 'vue-router'

// components
import Assignment from '@/components/Assignment'
import Assignment2 from '@/components/Assignment2'
import Assignment3 from '@/components/Assignment3'
import Searchboxtuan from '@/components/Searchboxtuan'
import Cardtuan from '@/components/Cardtuan'
import Searchboxkeith from '@/components/Searchboxkeith'
import Cardkeith from '@/components/Cardkeith'
import searchboxsefanja from '@/components/searchboxsefanja'
import cardsefanja from '@/components/cardsefanja'
import Spider from '@/components/Spider'
import ScheduleKeith from '../components/ScheduleKeith'
import Scheduletuan from '@/components/Scheduletuan'
import Schedulehometuan from '@/components/Schedulehometuan'
import ScheduleSefanja from '@/components/ScheduleSefanja'
import SubjectsPreview from '@/components/SubjectsPreview'
import DataManagerKeith from '../components/DataManagerKeith'
import Assignment4 from '../components/Assignment4'
import Assignment5 from '../components/Assignment5'
import ExamScheduleSefanja from '@/components/ExamScheduleSefanja'
import ExamScheduleTest from '@/components/ExamScheduleTest'
import EmailPreviewKeith from '../components/EmailPreviewKeith'
import StudentProfileKeith from '../components/StudentProfileKeith'
import LanguageSelectorKeith from '../components/LanguageSelectorKeith'
import VideoPlayerComponentKeith from '../components/VideoPlayerComponentKeith'
import WatchVideoViewKeith from '../views/WatchVideoViewKeith'
import GradesSefanja from '@/components/GradesSefanja'
import GradesTest from '@/components/GradesTest'
import NewsDetailsKeith from '../components/NewsDetailsKeith'
import NewsMenuKeith from '../components/NewsMenuKeith'
import Header from '../components/Header'
import Loginkeith from '../components/Loginkeith'
import Loginpagetuan from '../components/Loginpagetuan'
import LoginSefanja from '../components/LoginSefanja'
import NotesKeith from '../components/notes/NotesKeith'
import WeatherKeith from '../components/WeatherKeith'
import WeatherKeith2 from '../components/WeatherKeith2'
import StoresKeith from '../components/StoresKeith'
import StoresKeithNext from '../components/StoresKeithNext'
import SubmitAssignmentKeith from '../components/SubmitAssignmentKeith'
import QuizKeith from '../components/QuizKeith'
import DownloadAssignmentKeith from '@/components/DownloadAssignmentKeith'
import AssignmentSefanja from '@/components/AssignmentSefanja'
import ExamsKeith from '@/components/ExamsKeith'
import LogoutKeith from '@/components/LogoutKeith'
import pdfSefanja from '@/components/pdfSefanja'
import pdfSefanja2 from '@/components/pdfSefanja2'

// import views
import MainPage from '../views/MainPage'
import NewsViewTuan from '@/views/NewsViewTuan'
import MainPageKeith2 from '@/views/MainPageKeith2'
import MainPageSefanja from '@/views/MainPageSefanja'
import SmartWorldKeith from '@/views/SmartWorldKeith'
import ModuleViewKeith from '@/views/ModuleViewKeith'
import NewsDynamicTuan from '@/views/NewsDynamicTuan'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: 'Saxion | Prototype student portal'
    },
    component: SmartWorldKeith
  },
  {
    path: '/notes',
    name: 'noteskeith',
    meta: {
      title: 'Saxion | Notes'
    },
    component: NotesKeith
  },
  {
    path: '/newsdetailskeith',
    name: 'newsdetailskeith',
    component: NewsDetailsKeith
  },
  {
    path: '/header',
    name: 'header',
    component: Header
  },

  {
    path: '/newsmenukeith',
    name: 'newsmenukeith',
    component: NewsMenuKeith
  },
  {
    path: '/mainpage',
    name: 'mainpage',
    component: MainPage
  },
  {
    path: '/news/:id',
    name: 'newslisttuan',
    component: NewsViewTuan,
    meta: {
      title: 'Saxion | News'
    }
  },

  {
    path: '/newslist/:id',
    name: 'NewsViewKeith'
  },
  {
    path: '/module/:id',
    name: 'ModuleViewKeith',
    meta: {
      title: 'Saxion | Module'
    },
    component: ModuleViewKeith
  },
  {
    path: '/news',
    name: 'News',
    component: NewsViewTuan
  },
  {
    path: '/newsinfo/:id',
    name: 'NewsInfo',
    component: NewsDynamicTuan
  },
  {
    path: '/videoplayercomponentkeith',
    name: 'VideoPlayerComponentKeith',
    component: VideoPlayerComponentKeith
  },
  {
    path: '/watchvideoviewkeith',
    name: 'watchvideoviewkeith',
    component: WatchVideoViewKeith
  },
  {
    path: '/login',
    name: 'Login',
    component: Loginkeith
  },
  {
    name: 'assignment',
    path: '/assignment',
    component: Assignment
  },
  {
    name: 'assignment2',
    path: '/assignment2',
    component: Assignment2
  },
  {
    name: 'assignment3',
    path: '/assignment3',
    component: Assignment3
  },
  {
    name: 'assignment4',
    path: '/assignment4',
    component: Assignment4
  },

  {
    name: 'searchboxtuan',
    path: '/searchboxtuan',
    component: Searchboxtuan
  },
  {
    name: 'cardtuan',
    path: '/cardtuan',
    component: Cardtuan
  },
  {
    name: 'Searchboxsefanja',
    path: '/searchboxsefanja',
    component: searchboxsefanja
  },
  {
    name: 'Cardsefanja',
    path: '/cardsefanja',
    component: cardsefanja
  },
  {
    name: 'searchboxkeith',
    path: '/searchboxkeith',
    component: Searchboxkeith
  },
  {
    name: 'Cardkeith',
    path: '/cardkeith',
    component: Cardkeith
  },
  {
    name: 'Spider',
    path: '/spider',
    component: Spider
  },
  {
    name: 'GradesSefanja',
    path: '/gradessefanja',
    component: GradesSefanja
  },
  {
    name: 'Subjectspreview',
    path: '/subjectspreview',
    component: SubjectsPreview
  },
  {
    name: 'datamanagerkeith',
    path: '/datamanagerkeith',
    component: DataManagerKeith
  },
  {
    name: 'assignment5',
    path: '/assignment5',
    component: Assignment5
  },
  {
    name: 'ExamScheduleSefanja',
    path: '/examschedulesefanja',
    component: ExamScheduleSefanja
  },
  {
    name: 'ExamScheduleTest',
    path: '/examscheduleTest',
    component: ExamScheduleTest
  },
  {
    name: 'emailpreviewkeith',
    path: '/emailpreviewkeith',
    component: EmailPreviewKeith
  },
  {
    name: 'GradesTest',
    path: '/gradestest',
    component: GradesTest
  },
  {
    name: 'studentprofilekeith',
    path: '/profile',
    meta: {
      title: 'Saxion | Profile'
    },
    component: StudentProfileKeith
  },
  {
    name: 'languageselectorkeith',
    path: '/languageselectorkeith',
    component: LanguageSelectorKeith
  },

  {
    name: 'DataManagerKeith',
    path: '/datamanagerkeith',
    component: DataManagerKeith
  },

  {
    name: ScheduleKeith,
    path: '/schedulekeith',
    component: ScheduleKeith
  },
  {
    name: 'Scheduletuan',
    path: '/scheduletuan',
    component: Scheduletuan
  },
  {
    name: 'Schedulehometuan',
    path: '/schedulehometuan',
    component: Schedulehometuan
  },
  {
    name: 'ScheduleSefanja',
    path: '/schedulesefanja',
    component: ScheduleSefanja
  },
  {
    name: 'Loginpagetuan',
    path: '/logintuan',
    component: Loginpagetuan
  }, {
    name: 'Loginkeith',
    path: '/loginkeith',
    meta: {
      title: 'Saxion | Login'
    },
    component: Loginkeith
  },
  {
    name: 'LoginSefanja',
    path: '/loginsefanja',
    component: LoginSefanja
  },
  {
    name: 'submitasskeith',
    path: '/submitasskeith',
    component: SubmitAssignmentKeith
  },
  {
    name: 'weatherkeith',
    path: '/weatherkeith',
    component: WeatherKeith
  },
  {
    name: 'weatherkeith2',
    path: '/weatherkeith2',
    component: WeatherKeith2
  },
  {
    name: 'storeskeith',
    path: '/storeskeith',
    component: StoresKeith
  },
  {
    name: 'storeskeithnext',
    path: '/storeskeithnext',
    component: StoresKeithNext
  },
  {
    name: 'quizkeith',
    path: '/quizkeith',
    component: QuizKeith
  },
  {
    name: 'portal',
    path: '/portal',
    meta: {
      title: 'Saxion | Homepage'
    },
    component: MainPageKeith2
  },
  {
    name: 'mainpagesefanja',
    path: '/mainpagesefanja',
    component: MainPageSefanja
  },
  {
    name: 'logout',
    path: '/logout',
    component: LogoutKeith
  },
  {
    name: 'smartworldkeith',
    path: '/smartworldkeith',
    component: SmartWorldKeith
  },
  {
    name: 'examskeith',
    path: '/examskeith',
    component: ExamsKeith
  },
  {
    name: 'assignmentsefanja',
    path: '/assignmentsefanja',
    component: AssignmentSefanja
  },
  {
    name: 'pdfsefanja',
    path: '/pdfsefanja',
    component: pdfSefanja
  },
  {
    name: 'pdfsefanja2',
    path: '/pdfsefanja2',
    component: pdfSefanja2
  },
  {
    name: 'downloadasskeith',
    path: '/downloadasskeith',
    component: DownloadAssignmentKeith
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
