<template>

  <div>
    <v-card
    >
      <v-tabs
        v-model="tab"
        align-with-title
        background-color="primary"
        center-active
        dark--
      >
        <v-tabs-slider color="white"></v-tabs-slider>
        <!--keith repeat weeks-->
        <v-tab
          v-for="(topMenu,weekIndex) in moduleData.weekPages"
          :key="weekIndex"
        >
          {{ topMenu.top_menu_name }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <!--       keith repeat menu from weeks object-->
        <v-tab-item
          v-for="(weekPage,weekIndex) in moduleData.weekPages"
          :key="weekIndex"
        >

          <v-card>
            <v-tabs vertical>
              <!--          keith    repeat this weeks left menu items-->

              <v-tab v-for="(singlePage,sideMenuIndex) in weekPage.submenu" :key="sideMenuIndex">
                <v-icon left>
                  {{ singlePage.icon }}
                </v-icon>
                {{ singlePage.name }}
              </v-tab>

              <!--         keith     repeat selected menu content-->

              <v-tab-item v-for="(SubmenuItems,sideMenuIndex) in weekPage.submenu_items" :key="sideMenuIndex">

                <v-card
                  class="pa-2 mb-4"
                  outlined
                  elevation="2"
                  shaped
                  v-for="(task,i) in SubmenuItems.tasks" :key="i"
                >
                  <v-card-title>{{ task.title }}</v-card-title>
                  <v-card-subtitle>{{ task.subtitle }}</v-card-subtitle>
                  <v-card-text>{{ task.text }}</v-card-text>
                  <v-card-actions></v-card-actions>
                  <button v-on:click="pushState"> Update url</button>
                </v-card>

              </v-tab-item>

            </v-tabs>
          </v-card>

        </v-tab-item>

      </v-tabs-items>

    </v-card>

  </div>
</template>

<script>

import introToProgramming from '../data/module_data_introtoprogram'

export default {
  name: 'Assignment3',
  data () {
    return {
      moduleData: introToProgramming,
      skill: 0,
      number: 20,
      tab: null,
      weeks:
        [
          'Intro', 'Week 1', 'Week 2', 'Discussion', 'Extra'
        ],
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',

      moduleDataX:
        {
          module_code: 'SX12',
          weekPages: [{
            top_menu_name: 'intro',
            submenu: [{
              name: 'intro ass 1',
              icon: 'mdi-help-circle'
            },
            {
              name: 'intro ass 2',
              icon: 'mdi-help-circle'
            }
            ],
            submenu_items: [{
              tasks: [{
                title: 'intro ass 1a',
                subtitle: 'level 1',
                content: 'copy past is golden'
              }]
            },
            {
              tasks: [{
                title: 'intro ass 2a',
                subtitle: 'level 1',
                content: 'copy past is golden'
              }]
            }
            ]
          },
          {
            top_menu_name: 'week 1',
            submenu: [{
              name: 'week 1 assignment 1',
              icon: 'mdi-book-open-blank-variant'
            },
            {
              name: 'week 1 assignment 2',
              icon: 'mdi-book-open-blank-variant'
            }
            ],
            submenu_items: [{
              tasks: [{
                title: 'week 1 assignment 1a',
                subtitle: 'level 2',
                text: 'copy paste is golden'
              },
              {
                title: 'week 1 assignment 1b',
                subtitle: 'level 2',
                text: 'copy paste is golden'
              }
              ]
            },
            {
              tasks: [{
                title: 'week 1 assignment 2a',
                subtitle: 'level 2',
                text: 'copy paste is golden'
              },
              {
                title: 'week 1 assignment 2b',
                subtitle: 'level 2',
                text: 'copy paste is golden'
              }
              ]
            }
            ]
          }
          ]
        },

      links: [
        { title: 'Dashboard', icon: 'mdi-view-dashboard' },
        { title: 'Account', icon: 'mdi-account-box' },
        { title: 'Admin', icon: 'mdi-laptop' }
      ],
      e1: 1
    }
  },
  watch: {
    steps (val) {
      if (this.e1 > val) {
        this.e1 = val
      }
    }
  },

  methods: {
    backStep (n) {
      if (n !== 1) {
        this.e1 = n - 1
      }
      if (this.skill >= 20) {
        this.skill = this.skill - 20
      }
    },
    nextStep (n) {
      this.e1 = n + 1
      if (this.skill < 100) {
        this.skill = this.skill + 20
      }
    },
    pushState () {
      const state = { page_id: 1, user_id: 5 }
      const title = ''
      const url = '/course-code/intro-to-programming/week-1/assignment-2'
      history.pushState(state, title, url)
    }

  },

  computed: {
    submen: function () {
      const items = this.fitems.map(el => el)
      return items
    }
  }

}
</script>
