<template>
  <v-footer
    dark
    padless

  >
    <v-card
      class="flex"
      flat
      tile
    >
      <v-card-title class="primary">

        <v-btn
          class="mx-4"
          dark
          icon
          href="https://www.google.com/"
          target="_blank"
        >
          <v-icon size="24px">
            mdi-google
          </v-icon>
        </v-btn>
        <strong class="subheading">{{ $t("Google it yourself") }}!</strong>

        <v-spacer></v-spacer>

        <LanguageSelectorKeith v-if="!this.getUser().student_id"></LanguageSelectorKeith>

        <v-btn
          class="mx-4"
          outlined
          target="_blank"
          href="https://forms.gle/mkfPmC7AT5zCENQr7"
        >
          <v-icon size="24px">
            mdi-bullhorn
          </v-icon>
         Give Feedback Here!
        </v-btn>

        <v-btn
          v-for="(icon, index) in icons"
          :key="index"
          class="mx-4"
          dark
          icon
          v-bind:href="icon.src"
          target="_blank"
        >
          <v-icon size="24px">
            {{ icon.icon }}
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class=" white--text text-center">
        {{ new Date().getFullYear() }} — <strong>IT CORP Project 2.4 by Tuan, Sef, K</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import LanguageSelectorKeith from '@/components/LanguageSelectorKeith'

export default {
  components: {LanguageSelectorKeith},
  data: () => ({
    icons: [
      {icon: 'mdi-library', src: 'https://saxionbibliotheek.nl/'},
      {icon: 'mdi-youtube', src: 'https://www.youtube.com/'}
    ]
  }),
  methods: {
    ...mapMutations(['saveUser']),
    ...mapActions(['']),
    ...mapGetters(['getUser'])

  }
}
</script>

<style scoped>

</style>
