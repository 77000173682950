<template>
  <v-card
    class="mx-auto my-4"
    max-width="400"
  >
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="text-h5">
          {{ weather.name }}
        </v-list-item-title>
        <v-list-item-subtitle>{{ currentTime }}, {{ weather.weather[0].description }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-card-text>
      <v-row align="center">
        <v-col
          class="text-h2"
          cols="6"
        >
          {{ weather.main.temp }} &deg;C
        </v-col>
        <v-col cols="6">
          <v-img
            v-bind:src='"http://openweathermap.org/img/wn/"+weather.weather[0].icon+".png"'
            alt="Sunny image"
            max-width="128px"
            class="mx-auto"
          ></v-img>
        </v-col>
      </v-row>
    </v-card-text>

    <v-list-item>
      <v-list-item-icon>
        <v-icon>mdi-send</v-icon>
      </v-list-item-icon>
      <v-list-item-subtitle>{{ weather.wind.speed }} km/h Wind speed</v-list-item-subtitle>
    </v-list-item>

    <v-list-item>
      <v-list-item-icon>
        <v-icon>mdi-cloud-download</v-icon>
      </v-list-item-icon>
      <v-list-item-subtitle>{{ weather.main.humidity }}% Humidity</v-list-item-subtitle>
    </v-list-item>

    <v-divider></v-divider>

    <v-card-actions>
      <v-btn text v-on:click="getWeather">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </v-card-actions>

    <p id="demo"></p>

  </v-card>
</template>

<script>
import moment from 'moment'

export default {
  name: 'WeatherKeith',
  data () {
    return {
      weather: {
        coord: { lon: -0.1257, lat: 51.5085 },
        weather: [{ id: 804, main: 'Clouds', description: 'overcast clouds', icon: '01d' }],
        base: 'stations',
        main: {
          temp: 28,
          feels_like: 28.95,
          temp_min: 28.62,
          temp_max: 28.26,
          pressure: 1010,
          humidity: 94
        },
        visibility: 9000,
        wind: { speed: 0.51, deg: 0 },
        clouds: { all: 90 },
        dt: 1624178140,
        sys: { type: 2, id: 2019646, country: 'GB', sunrise: 1624160575, sunset: 1624220473 },
        timezone: 3600,
        id: 2643743,
        name: 'London',
        cod: 200
      },
      userLat: 52.253432399999994,
      userLong: 6.1729635

    }
  },
  methods: {
    getLocation () {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.showPosition)
      } else {
        alert('Geolocation is not supported by this browser.')
      }
    },

    showPosition (position) {
      this.userLat = position.coords.latitude
      this.userLong = position.coords.longitude
    },

    getWeather () {
      // get location
      this.getLocation()

      // get weather
      const openWeatherApi = `http://api.openweathermap.org/data/2.5/weather?lat=${this.userLat}&lon=${this.userLong}&units=metric&APPID=0060c9245c1dd2b8efd8b8bc76ff5345`

      fetch(
        openWeatherApi,
        {
          method: 'get'
        }
      )
        .then((response) => {
          return response.json()
        })
        .then((jsonData) => {
          jsonData.main.temp = Math.round(jsonData.main.temp)
          this.weather = jsonData
        })
    }

  },

  computed: {
    currentTime () {
      return moment(this.weather.dt, 'X').format('ddd hh:mm')
    }

  },

  mounted: function () {
    this.getWeather()
  }

}
</script>

<style scoped>

</style>
