<template>
  <div>

    <button v-for="entry in lange" :key="entry.name" v-on:click="changeLocale(entry.lang)">
      <flag :iso="entry.flag" v-bind:squared=false> {{ entry.name }}</flag>

    </button>

  </div>
</template>

<script>
import { translations } from '../plugins/translations'
import { i18n } from '../plugins/i18n'

export default {
  name: 'LanguageSelectorKeith',
  data () {
    return {
      lange: translations,
      email: 'email',
      password: 'password'
    }
  },
  methods: {
    changeLocale (locale) {
      i18n.locale = locale
    }
  }

}
</script>

<style scoped>

</style>
