<template>

  <v-card

  >

    <v-toolbar
      color="primary"
      dark
    >
      <v-toolbar-title>{{ $t("Subjects") }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-icon>mdi-book-open-variant</v-icon>

    </v-toolbar>

    <v-list max-height="400" class="overflow-auto">

      <v-list-item-group
        v-model="model"
        mandatory
        color="indigo"
      >
        <v-list-item
          v-for="(item, i) in items"
          :key="i" v-bind:to="'module/'+  item.module_code"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>

          <v-list-item-content>

            <v-list-item-title v-text="item.subject_name"></v-list-item-title>

          </v-list-item-content>

        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>

</template>

<script>
import { fbGetAllTree } from '@/plugins/dbOperations'

export default {
  data: () => ({

    items: [{
      subject_name: 'Organisation and IT',
      year_of_study: 1,
      teacher_id: '1AVACHM',
      icon: 'mdi-database',
      module_code: 'sx12'
    }, {
      subject_name: 'Requirements Engineering',
      year_of_study: 3,
      teacher_id: '1DV1LD',
      icon: 'mdi-contrast-circle',
      module_code: 'sx13'
    }
    ],
    model: 1
  }),
  methods: {

    async getFBData () {
      const subjectData = await fbGetAllTree('subject_list')
      this.items = subjectData
    }
  },

  mounted: function () {
    this.getFBData()
  }
}
</script>

<style scoped>

</style>
