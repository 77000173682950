<template>
  <div>

    <VideoPlayerComponentKeith videoId="2dZiMBwX_5Q"></VideoPlayerComponentKeith>
    <VideoPlayerComponentKeith videoId="o7_vSYYT5mI"></VideoPlayerComponentKeith>

  </div>
</template>

<script>
import VideoPlayerComponentKeith from '../components/VideoPlayerComponentKeith'

export default {
  name: 'WatchVideoViewKeith',
  components: { VideoPlayerComponentKeith },
  data () {
    return {
      videoId: 'Ka_uOIf_v5g',
      moduleData: {
        moduleName: 'java',
        videoId: 'Hdf5OmERt0g'
      },
      videos: []
    }
  }
}
</script>

<style scoped>

</style>
