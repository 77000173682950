<template>
  <div>

    <h2>this is next component</h2>

    <v-btn v-on:click="showCount">show count</v-btn>
    {{ this.$store.state.count }}
    {{ localCount }}

  </div>
</template>

<script>

export default {
  name: 'StoresKeith',
  data () {
    return {
      localCount: 0
    }
  }

}
</script>

<style scoped>

</style>
