<template>
  <div>

    <Assignment5 v-bind:moduleData="moduleData"></Assignment5>

  </div>
</template>

<script>
import Assignment5 from '@/components/Assignment5'
import { db, fbGetSubTreeWithKey } from '@/plugins/dbOperations'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  components: { Assignment5 },
  name: 'ModuleViewKeith',

  data () {
    return {
      moduleData: {
        module_name: 'haha'
      }
    }
  },
  methods: {
    ...mapMutations(['saveUser']),
    ...mapActions(['']),
    ...mapGetters(['getUser']),

    async getModuleData () {
      const dbModuleData = await fbGetSubTreeWithKey('modules', 'module_code', this.$route.params.id)
      this.moduleData = dbModuleData
    },

    // find a key value from a tree's subtree by keith
    async updateModuleDbData () {
      let myDbData
      await db.ref('modules').on('value', (snapshot) => {
        snapshot.forEach((childSnapshot) => {
          const childData = childSnapshot.val()
          if (childData.module_code.toLowerCase() === this.$route.params.id.toLowerCase()) {
            myDbData = childSnapshot.val()

            myDbData.object_key = childSnapshot.key

            this.addSubmissionPoint(myDbData)

            this.moduleData = myDbData
            return myDbData
          }
        })
      })
      return myDbData
    },

    addSubmissionPoint (myDbData) {
      const submenu = {
        name: 'Submit Assignment',
        icon: 'mdi-book-arrow-right'
      }
      // add or not
      myDbData.weekPages[0].submenu.push(submenu)
    }

  },
  watch: {

    $route: {
      handler: (to, from) => {
        document.title = to.meta.title || 'Your Website'
      },
      immediate: true
    }

  },
  mounted: function () {
    // this.getModuleData() // this will not auto update chat messages
    this.updateModuleDbData() // this will update chat messages
  },
  computed: {}

}
</script>

<style scoped>

</style>
