import firebase from 'firebase/app'
import 'firebase/database'

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyBP_LHCXd6uGAMb5_rNBNA-8AWLRnDUQdA',
  authDomain: 'student-elo.firebaseapp.com',
  databaseURL: 'https://student-elo-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'student-elo',
  storageBucket: 'student-elo.appspot.com',
  messagingSenderId: '462682367510',
  appId: '1:462682367510:web:cc38193cfbe4dda8613737'
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)

export const db = firebase.database()

// made by keith
export function fbAddItem (treeName, object) {
  if (treeName === '' || object === '') {
    console.log('tree name or object cannot be empty')
    return
  }

  db.ref(treeName).push(
    object
  )
}

// object insert in db by keith
export async function fbAddCommentToModule (moduleCode, top, side, task, newComment) {
  const module = await fbGetSubTreeWithKey('modules', 'module_code', moduleCode)

  // check if comment property exist
  if (!module.weekPages[top].submenu_items[side].tasks[task].hasOwnProperty('questions')) {
    module.weekPages[top].submenu_items[side].tasks[task].questions = []
  }

  const commentNumber = module.weekPages[top].submenu_items[side].tasks[task].questions.length
  const address = 'modules/' + module.object_key + '/weekPages/' + top + '/submenu_items/' + side + '/tasks/' + task + '/questions/' + commentNumber
  await db.ref(address).update(newComment)
}

// object insert in db by keith
export async function fbInsertObject (treeName, key, value, path, object) {
  const subTree = await fbGetSubTreeWithKey(treeName, key, value)
  const address = treeName + '/' + subTree.object_key + '/' + path
  await db.ref(address).update(object)
}

// made by keith
export function fbDeleteTree (treeName) {
  if (treeName === '') {
    console.log('tree name cannot be empty')
    return
  }

  db.ref(treeName).set(
    {}
  )
}

// get db data by keith
export async function fbGetAllTree (treeName) {
  const myDbData = []
  await db.ref(treeName).once('value', (snapshot) => {
    snapshot.forEach((childSnapshot) => {
      myDbData.push(childSnapshot.val())
    })
  })
  return myDbData
}

// get db data by keith
export async function fbGetSubTree (treeName, subTreeName) {
  let myDbData
  await db.ref(treeName).once('value', (snapshot) => {
    snapshot.forEach((childSnapshot) => {
      if (childSnapshot.key === subTreeName) {
        myDbData = childSnapshot.val()
      }
    })
  })
  return myDbData
}

// find a key value from a tree's subtree by keith
export async function fbGetSubTreeWithKey (treeName, key, value) {
  key = key.toLowerCase()
  let myDbData
  await db.ref(treeName).once('value', (snapshot) => {
    snapshot.forEach((childSnapshot) => {
      const childData = childSnapshot.val()

      if (childData[key] === value) {
        myDbData = childSnapshot.val()
        myDbData.object_key = childSnapshot.key
        return myDbData
      }
    })
  })

  return myDbData
}

// @todo sefanja
export async function fbUpdateItem (treeName, key, value, property, newValue) {
  const subTree = await fbGetSubTreeWithKey(treeName, key, value)
  await db.ref('students/' + subTree.object_key).update({
    [property]: newValue
  })
}

export default { fbAddItem, fbDeleteTree, fbGetSubTree, fbGetAllTree, fbGetSubTreeWithKey }
