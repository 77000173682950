import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    count: 0,
    myVal: 'hahahaha',
    todos: [
      { id: 1, text: '...', done: true },
      { id: 2, text: 'number two', done: false }
    ],
    user: {}
  },
  mutations: {
    incrementCount (state) {
      state.count++
    },
    incrementCountPara (state, n) {
      state.count += n
    },
    saveUser (state, user) {
      state.user = user
    }

  },
  getters: {
    // ...
    getTodoById: (state) => (id) => {
      return state.todos.find(todo => todo.id === id)
    },
    getUser (state) {
      return state.user
    }

  },

  actions: {
    incrementAction (context) {
      context.commit('incrementCount')
    }

  },
  modules: {}

})
