<template>
  <v-card

  >
    <v-toolbar
      color="primary"
      dark

    >

      <v-toolbar-title>{{ $t("Emails") }}</v-toolbar-title>

      <v-spacer></v-spacer>
      <v-btn icon v-on:click="readMail">
        <v-icon>mdi-email</v-icon>
      </v-btn>

    </v-toolbar>

    <v-list two-line height="400" class="overflow-auto">
      <v-list-item-group
        v-model="selected"

        active-class="pink--text"
        multiple
      >

        <v-alert type="warning" v-if="error">
          {{ error }}
        </v-alert>

        <template>
          <v-list-item v-for="(email, index) in emails" :key="index">
            <template v-slot:default="{ active }">
              <v-list-item-content>

                <v-list-item-title v-text="email.subject"></v-list-item-title>

                <v-list-item-subtitle
                  class="text--primary"
                  v-text="email.bodyPreview"
                ></v-list-item-subtitle>

                <v-list-item-subtitle v-text="email.from.emailAddress.name"></v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-list-item-action-text v-text="timeAgo(email.sentDateTime)"></v-list-item-action-text>

                <v-icon
                  v-if="!active"
                  color="grey lighten-1"
                >
                  mdi-star-outline
                </v-icon>

                <v-icon
                  v-else
                  color="yellow darken-3"
                >
                  mdi-star
                </v-icon>
              </v-list-item-action>
            </template>
          </v-list-item>

        </template>
      </v-list-item-group>

      <v-list-item>
        <v-btn href="https://outlook.live.com/mail/0/inbox" target="_blank">Outlook.com</v-btn>
        <v-spacer></v-spacer>

        <v-btn icon v-if="!loggedin" v-on:click="doLogin">
          <v-icon>mdi-login</v-icon>
        </v-btn>

        <v-btn icon v-if="loggedin" v-on:click="doSoftLogout">
          <v-icon>mdi-logout</v-icon>
        </v-btn>
      </v-list-item>

    </v-list>

    <!--    email preview by keith-->
    <div>

    </div>

  </v-card>

</template>

<script>

import graph from '../services/graph'
import auth from '../services/auth'
import moment from 'moment'

export default {
  name: 'EmailPreviewKeith',

  data: () => ({
    selected: [],

    emails: [],
    dummyEmails: [{
      from:
        {
          emailAddress:
            { name: 'dummy sender name' }
        },
      subject: 'dummy subject',
      bodyPreview: 'dummy email preview',
      sentDateTime: '2021-06-18T04:11:15Z'
    },
    {
      from:
          {
            emailAddress:
              { name: 'Ali Connors' }
          },
      subject: 'Brunch this weekend?',
      bodyPreview: "I'll be in your neighborhood doing errands this weekend. Do you want to hang out?",
      sentDateTime: '2021-06-15T04:11:15Z'
    },
    {
      from:
          {
            emailAddress:
              { name: 'Scrott, Jennifer' }
          },
      subject: 'Summer BBQ',
      bodyPreview: "Wish I could come, but I'm out of town this weekend.",
      sentDateTime: '2021-06-12T04:11:15Z'
    },
    {
      from:
          {
            emailAddress:
              { name: 'Sandra Adams' }
          },
      subject: 'Oui oui',
      bodyPreview: 'Do you have Paris recommendations? Have you ever been?',
      sentDateTime: '2021-06-10T04:11:15Z'
    }
    ],
    error: '',
    // User account object synced with MSAL getAccount()
    user: {},
    // Access token fetched via MSAL for calling Graph API
    accessToken: '',
    // Details fetched from Graph API, user object and photo
    graphDetails: null,
    loginStatus: null,
    loggedin: ''

  }),

  watch: {
    // If user changes, make calls to Graph API
    loggedin: function () {
      if (this.loggedin) {
        this.readMail()
      } else {
        this.emails = this.dummyEmails
      }
    }

  },
  methods: {
    async readMail () {
      if (auth.user()) {
        const data = await graph.getEmails()
        this.emails = data.value
      }
    },
    // Update user from MSAL
    updateUser () {
      this.user = auth.user()
    },
    logged () {
      this.user = auth.user()
      this.loginStatus = true
    },
    notLogged () {
      this.user = auth.user()
      this.loginStatus = false
    },
    async doLogin () {
      try {
        await auth.login()
        this.loggedin = true
        this.$emit('loginComplete')
        // await this.readMail()
      } catch (err) {
        this.error = err.toString()
      }
    },

    doSoftLogout () {
      this.user = null
      this.graphDetails = null
      this.userDetails = null
      this.graphPhoto = null
      this.loggedin = false
      auth.clearLocal()
    },

    // Full logout local & server side
    doLogout () {
      auth.logout()
      this.loggedin = false
      // this.emails = this.dummyEmails
      this.$emit('logoutComplete')
    },

    timeAgo (timeDate) {
      const timeBefore = moment(timeDate).fromNow()
      return timeBefore
    },
    emailChecker: function () {
      setInterval(() => {
        this.readMail()
      }, 10000)
    }

  },
  async created () {
    // Basic setup of MSAL helper with client id, or give up
    const msAppId = 'c6ea78ec-8901-4915-bd30-8ceb6bdca7e5'
    await auth.configure(msAppId, true)
    // Restore any cached or saved local user
    this.user = auth.user()
  },

  mounted: function () {
    if (auth.user()) {
      this.loggedin = true
      this.readMail()
    } else {
      this.emails = this.dummyEmails
    }
  }

}
</script>

<style scoped>

</style>
