<template>
  <v-card
    class="mx-auto my-4"
    max-width="600"
    shaped
  >
    <v-img
      height="250"
      src="https://pbs.twimg.com/media/BxFAKlxCYAErfXw.png"
    ></v-img>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      class="pa-3"
    >
      <v-text-field
        v-model="name"
        :rules="nameRules"
        label="E-mail"
        required
        prepend-icon=" mdi-account-circle"
        suffix="@student.saxion.nl"
      ></v-text-field>

      <v-text-field
        v-model="password"
        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
        :type="show1 ? 'text' : 'password'"
        name="input-10-1"
        label="Password"
        prepend-icon=" mdi-lock"
        counter
        @click:append="show1 = !show1"
      ></v-text-field>

      <v-checkbox
        v-model="checkbox"
        label="Remember login?"
        required
      ></v-checkbox>

      <v-btn
        :disabled="!valid"
        color="success"
        class="mr-4"
        @click="validate"
      >
        Login
      </v-btn>

      <v-btn
        color="error"
        class="mr-4"
        @click="reset"
      >
        Forgot Password
      </v-btn>
    </v-form>
  </v-card>
</template>

<script>
export default {
  name: 'LoginSefanja',
  theme: {
    options: {
      customProperties: true
    }
  },
  data: () => ({
    valid: true,
    name: '',
    nameRules: [
      v => !!v || 'E-mail is required',
      v => (v && v.length <= 100) || 'E-mail must be less than 100 characters'
    ],
    email: '',
    emailRules: [
      v => !!v || 'Password is required'
    ],
    select: null,
    checkbox: false,
    show1: false,
    show2: true,
    show3: false,
    show4: false,
    password: 'Password',
    rules: {
      required: value => !!value || 'Required.',
      emailMatch: () => ('The email and password you entered don\'t match')
    },

    methods: {
      validate () {
        this.$refs.form.validate()
      },
      reset () {
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      }
    }
  })
}
</script>

<style scoped>
.text-green input {
  color: green !important;
}
</style>
