<template>
  <div>
    <v-card
      class="mx-auto col-8 my-4"
      max-width=""
      outlined
    >
      <v-list-item three-line>
        <v-list-item-content>
          <div class="text-overline mb-4">
            Why type when you can paste
          </div>
          <v-list-item-title class="text-h5 mb-1">
            Data Manager
          </v-list-item-title>
          <v-list-item-subtitle>by Keith</v-list-item-subtitle>
        </v-list-item-content>

      </v-list-item>

      <v-card-text>

        <v-row no-gutters>
          <v-col cols="8">
            <v-text-field
              v-model="treeName"
              label="Tree name"
              clearable
            ></v-text-field>
          </v-col>

          <v-col cols="4" class="pa-2"> Example: <br>
            testDump
          </v-col>

        </v-row>

        <v-row no-gutters>

          <v-col cols="8">
            <v-textarea
              v-model="jsonData"
              name="input-7-4"
              label="json data"
            ></v-textarea>
          </v-col>

          <v-col cols="4" class="pa-2"> Example: <br>
            [<br>
            {
            "name": "Teddie Chate",<br>
            "email": "tchate0@google.com.au" },
            <br>
            {
            "name": "Ethelin Hince",<br>
            "email": "ehince1@sbwire.com"} <br>
            ]
          </v-col>

        </v-row>

        <v-checkbox
          v-model="switch1"
          :label="`Overwrite whole tree`"
        ></v-checkbox>

        <v-alert type="warning" v-if="error">
          {{ errorMessage }}
        </v-alert>

      </v-card-text>

      <v-card-actions>

        <v-btn depressed v-on:click="process">
          Cross Fingers
        </v-btn>
      </v-card-actions>
    </v-card>

  </div>
</template>

<script>

import { fbAddItem, fbDeleteTree } from '../plugins/dbOperations'

export default {
  name: 'DataManagerKeith',
  data () {
    return {
      treeName: 'testDump',
      jsonData: '[\n' +
        '{ name: "Teddie Chate",\n' +
        '"email": "tchate0@google.com.au" },\n' +
        '{ "name": "Ethelin Hince",\n' +
        '"email": "ehince1@sbwire.com"}\n' +
        ']',
      switch1: false,
      switch2: false,
      error: false,
      errorMessage: ''
    }
  },
  methods: {
    process () {
      if (this.treeName === '' || this.jsonData === '') {
        this.errorMessage = 'Tree name or json data is empty'
        this.error = true
        return
      }

      this.error = false

      if (this.switch1 === true) {
        fbDeleteTree(this.treeName)
      }

      let jsonConverted
      try {
        jsonConverted = JSON.parse(this.jsonData)
      } catch (e) {
        this.errorMessage = 'json is broken'
        this.error = true
        console.log(e)
        return
      }

      jsonConverted.forEach((item) => {
        fbAddItem(this.treeName, item)
      }
      )
    }

  }
}
</script>

<style scoped>

</style>
