<template>

  <v-app-bar
    app
    color="white"
    dark
  >
    <div class="d-flex align-center">

      <v-btn v-bind:to="'/'" text>

        <v-img
          alt="Vuetify Name"
          min-width="100"
          src="@/assets/saxion-logo.png"
          transition="scale-transition"
          width="100"
        />
      </v-btn>

    </div>

    <v-spacer></v-spacer>

    <v-btn
      v-bind:to="'/portal'"
      text
      color="primary"
      class="mr-1"
      v-if="this.getUser().email"
    >
      {{ $t("Student Portal") }}
      <v-icon color="primary">mdi-open-in-new</v-icon>
    </v-btn>

    <v-btn v-bind:to="'/profile'" icon class="mr-1" v-if="this.getUser().email">
      <v-img max-height="50"
             max-width="50" class="rounded-circle" v-bind:src="this.getUser().profile_pic"></v-img>

    </v-btn>

    <v-btn
      md="12"
      class=" "
      color="primary"
      to="/login"
      v-if="!this.getUser().email"
    > Log in
      <v-icon size="24px">
        mdi-login
      </v-icon>
    </v-btn>

  </v-app-bar>

</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { i18n } from '@/plugins/i18n'

export default {
  name: 'Header',
  data: () => ({
    items: [
      { title: 'Settings' },
      { title: 'Log out' }
    ],
    user: {}
  }),
  computed: {
    loggedIn: function () {
      return false
    }

  },
  methods: {
    ...mapMutations(['saveUser']),
    ...mapActions(['']),
    ...mapGetters(['getUser']),

    loadUserDetails () {
      let user = {}
      if (localStorage.getItem('user')) {
        user = localStorage.getItem('user')
        user = JSON.parse(user)

        this.saveUser(user)
        i18n.locale = this.getUser().language_pref
      } else {
        this.$router.push('login') // redirect
      }
    }

  },
  mounted: function () {
    this.loadUserDetails()
  }

}
</script>

<style scoped>

</style>
