import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { translations } from './translations'

Vue.use(VueI18n)

const messages = translations.reduce(function (acc, cur, i) {
  acc[cur.lang] = cur
  return acc
}, {})

export const i18n = new VueI18n({
  locale: 'en', // set locale
  fallbackLocale: 'es', // set fallback locale
  messages, // set locale messages
  silentTranslationWarn: true // turn off translation warnings in console
})
