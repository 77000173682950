<template>
  <v-row>
    <v-col cols="11" class="mx-auto">
      <v-card ref="form"
              class="my-4"

      >

        <v-toolbar
          color="primary"
          dark
        >
          <v-toolbar-title>{{ $t("Account") }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon>
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </v-toolbar>

        <v-container class=" ">
          <v-row
            no-gutters>
            <v-col md="4" class=" ">
              <v-img max-height="100"
                     max-width="100" class="rounded-circle mx-auto" v-bind:src="this.getUser().profile_pic"></v-img>
              <v-card-title class="justify-center">{{ student.full_name }}</v-card-title>

              <v-card-subtitle class="text-center"> {{ student.email }}</v-card-subtitle>
            </v-col>

            <v-col md="8">
              <v-simple-table dense>
                <template v-slot:default>
                  <tbody>
                  <tr
                  >
                    <td>{{ $t("Student number") }}:</td>
                    <td>{{ student.student_id }}</td>
                  </tr>

                  <tr>
                    <td> {{ $t("Personal email") }}:</td>
                    <td>{{ student.personal_email }}</td>
                  </tr>
                  <tr>
                    <td> {{ $t("Phone") }}:</td>
                    <td> {{ student.phone }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("Address") }}:</td>
                    <td> {{ student.address }},<br>
                      {{ student.city }}, {{ student.post_code }}, <br>
                      {{ student.country }}
                    </td>
                  </tr>

                  <tr>
                    <td></td>
                    <td></td>
                  </tr>

                  <tr>
                    <td>{{ $t("Portal Language") }}:</td>
                    <td>{{ languageFull }}

                    </td>
                  </tr>

                  <tr>
                    <td></td>
                    <td>
                      <v-btn-toggle
                        tile
                        color="primary"
                        group
                        v-model="student.language_pref"
                      >

                        <v-btn :value="entry.lang"
                               v-for="entry in languages"
                               v-on:click="changeLocale(entry.lang)" :key="entry.lang">
                          <flag :iso="entry.flag" v-bind:squared=false> {{ entry.name }}</flag>

                        </v-btn>

                      </v-btn-toggle>

                    </td>
                  </tr>

                  </tbody>
                </template>
              </v-simple-table>
            </v-col>

          </v-row>

        </v-container>

        <v-card-actions>

          <v-spacer></v-spacer>

          <v-btn
            md="12"
            v-on:click="logoutkeith"
          > Log out
            <v-icon size="24px">
              mdi-logout
            </v-icon>
          </v-btn>
        </v-card-actions>
        <v-progress-linear
          v-if="loading"
          indeterminate
          color="green"
        ></v-progress-linear>
      </v-card>
    </v-col>
  </v-row>

</template>

<script>
import { translations } from '../plugins/translations'
import { i18n } from '../plugins/i18n'
import { fbGetSubTreeWithKey, fbUpdateItem } from '../plugins/dbOperations'
import { mapActions, mapGetters, mapMutations } from 'vuex'


export default {
  name: 'StudentProfileKeith',
  data () {
    return {
      loading: false,
      pullStudentId: '473002',
      languages: translations,
      student: {
        first_name: 'Colet',
        last_name: 'Mawford',
        full_name: 'Colet Mawford',
        student_id: '485644',
        email: '485644@student.saxion.nl',
        password: '4967',
        role: 'student',
        address: '68 Mcbride Way',
        city: 'Delft',
        country: 'Netherlands',
        post_code: '2614',
        phone: '+31 (819) 207-9481',
        personal_email: 'cmawford0@theguardian.com',
        gender: 'F',
        profile_pic: 'M',
        language_pref: 'fr'
      },
      defaultLang: 'en',
      text: 'center',
      icon: 'justify',
      toggle_none: null,
      toggle_one: 0,
      toggle_exclusive: 2,
      toggle_multiple: [0, 1, 2]

    }
  },
  methods: {
    ...mapMutations(['saveUser']),
    ...mapActions(['']),
    ...mapGetters(['getUser']),

    updateLang (locale) {
      fbUpdateItem('students', 'student_id', this.getUser().student_id, 'language_pref', locale)
    },

    async getStudent () {
      const student = await fbGetSubTreeWithKey('students', 'student_id', this.pullStudentId)
      this.student = student
    },
    changeLocale (locale) {
      this.$store.state.user.language_pref = locale
      localStorage.setItem('user', JSON.stringify(this.getUser()))

      i18n.locale = locale
      this.updateLang(locale)
    },
    loadUser () {
      this.student = localStorage.getItem('user')
    },

    logoutkeith () {
      this.loading = true
      setTimeout(() => {
        this.saveUser({})
        localStorage.clear()
        i18n.locale = 'en'
        this.$router.push('logout') // redirect
      }, 2000)
    }

  },
  computed: {
    languageFull () {
      const portalLang = this.languages.filter(el => el.lang === this.student.language_pref)
      return portalLang[0].name
    }
  },
  mounted: function () {
    this.student = this.getUser()
  },

  watch: {
    $route: {
      handler: (to, from) => {
        document.title = to.meta.title || 'Your Website'
      },
      immediate: true
    }
  }

}
</script>

<style scoped>

</style>
