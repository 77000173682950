<template>

  <v-card
    class="mx-auto "
  >
    <v-toolbar
      color="primary"
      dark
    >
      <v-toolbar-title>{{ newsItem.title }}</v-toolbar-title>
      <v-spacer></v-spacer>

    </v-toolbar>

    <v-card-text class="text-h6">

      <v-spacer></v-spacer>
      <span v-html="newsItem.content"></span>

    </v-card-text>

    <v-card-actions>
      <v-list-item class="grow">
        <v-list-item-avatar>
          <v-img
            class="elevation-6"

            v-bind:src="newsItem.image_source"
          ></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title v-text="newsItem.author"></v-list-item-title>
        </v-list-item-content>

        <v-row
          align="center"
          justify="end"
        >
          <v-icon class="mr-1">
            mdi-heart
          </v-icon>
          <span class="subheading mr-2">256</span>
          <span class="mr-1">·</span>
          <v-icon class="mr-1">
            mdi-share-variant
          </v-icon>
          <span class="subheading">45</span>
        </v-row>
      </v-list-item>
    </v-card-actions>
  </v-card>

</template>

<script>
export default {
  name: 'NewsInfoTuan',

  props: {
    newsItem: Object
  }
}
</script>

<style scoped>

</style>
