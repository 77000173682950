<template>

  <v-row class="">
    <v-col>
      <v-sheet height="450">
        <v-calendar
          ref="calendar"
          :now="today"
          :value="today"
          :events="events"
          color="primary"
          type="week"
          v-model="value"
        >
          <template v-slot:day-body="{ date, week }">
            <div
              class="v-current-time"
              :class="{ first: date === week[0].date }"
              :style="{ top: nowY }"
            ></div>
          </template>
        </v-calendar>
      </v-sheet>
    </v-col>
  </v-row>

</template>

<script>
export default {
  data: () => ({
    value: '',
    ready: false,
    today: '2021-06-11',
    events: [
      {
        name: 'Group Meeting',
        start: '2021-06-07 09:00',
        end: '2021-06-07 10:00'
      },
      {
        name: 'Introduction to Programming',
        start: '2021-06-11 12:30',
        end: '2021-06-11 14:45'
      },
      {
        name: 'Android Programming',
        start: '2021-06-09 12:30',
        end: '2021-06-09 15:30'
      },
      {
        name: 'Introduction to Programming',
        start: '2021-06-08 13:00',
        end: '2021-06-08 16:30'
      },
      {
        name: 'Android Programming',
        start: '2021-06-11 15:30',
        end: '2021-06-11 17:00'
      }
    ]
  }),
  computed: {
    cal () {
      return this.ready ? this.$refs.calendar : null
    },
    nowY () {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px'
    }
  },
  mounted () {
    this.$refs.calendar.scrollToTime('08:00')
    this.ready = true
    this.scrollToTime()
    this.updateTime()
  },
  methods: {
    getCurrentTime () {
      return this.cal ? this.cal.times.now.hour * 60 + this.cal.times.now.minute : 0
    },
    scrollToTime () {
      const time = this.getCurrentTime()
      const first = Math.max(0, time - (time % 30) - 30)

      this.cal.scrollToTime(first)
    },
    updateTime () {
      setInterval(() => this.cal.updateTimes(), 60 * 1000)
    }
  }
}
</script>

<style scoped lang="scss">
.my-event {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 2px;
  background-color: #1867c0;
  color: #ffffff;
  border: 1px solid #1867c0;
  font-size: 12px;
  padding: 3px;
  cursor: pointer;
  margin-bottom: 1px;
  left: 4px;
  margin-right: 8px;
  position: relative;
}

.my-event.with-time {
  position: absolute;
  right: 4px;
  margin-right: 0px;
}

.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: '';
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}

</style>
