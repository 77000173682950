<template>
  <div>

    sdf

    <v-btn v-on:click="showCount">add count direct</v-btn>
    <v-btn v-on:click="addCount">add count</v-btn>
    <v-btn v-on:click="todo">todo</v-btn>

    <v-btn v-on:click="addthree">add 3 count</v-btn>

    <v-btn v-on:click="addthreeAction">add 3 action</v-btn>

    {{ this.$store.state.count }}
    {{ count }}
    {{ myVal }}
    {{ myTodo }}
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'StoresKeith',
  data () {
    return {
      localCount: 5,
      myTodo: 'a',
      user: undefined
    }
  },

  methods: {
    ...mapMutations(['incrementCountPara']),

    showCount () {
      this.$store.state.count++
    },
    addCount () {
      this.$store.commit('incrementCount')
    },
    todo () {
      this.myTodo = this.getTodoById(2)
    },
    addthree () {
      this.incrementCountPara(3)
    },
    addthreeAction () {
      this.incrementAction()
    }

  },

  computed: {
    ...mapState(['myVal', 'count']),
    ...mapGetters(['getTodoById']),
    ...mapActions(['incrementAction'])

  }
}
</script>

<style scoped>

</style>
