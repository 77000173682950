<template>
  <v-card
    class="mx-auto my-4"
    max-width="80%"
  >
    <v-img
      class="white--text align-end"
      height="300px"
      src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Saxion_Deventer_2012.jpg/800px-Saxion_Deventer_2012.jpg"
    >
      <v-card-title>See you soon</v-card-title>
    </v-img>

    <v-card-subtitle class="pb-0">
      2.4 IT-CORP ELO 2021
    </v-card-subtitle>

    <v-card-text class="text--primary">
      <div>Tuan, Sef, K</div>

    </v-card-text>

    <v-card-actions>

      <router-link v-bind:to="'news/news-1'">
        <v-btn
          color="primary"
        > News
        </v-btn>
      </router-link>

    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'LogoutKeith',
  methods: {
    ...mapMutations(['saveUser']),
    ...mapActions(['']),
    ...mapGetters(['getUser'])

  },

  mounted () {

  }
}
</script>

<style scoped>

</style>
