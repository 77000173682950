<template>

  <div>
    <v-card
      class="mx-auto my-4"
      max-width="500"
      outlined
    >

      <v-toolbar dark color="primary" flat height="40">
        <v-toolbar-title>Submit Assignment</v-toolbar-title>

        <v-spacer></v-spacer>
        <v-card-title class="body-2 text--white">{{ student_id }}</v-card-title>
      </v-toolbar>

      <v-card-title>
        Module: {{ module_code }}
      </v-card-title>
      <v-card-subtitle>Filesize limit 5 MB per file</v-card-subtitle>
      <v-card-text>

        <v-file-input
          chips
          counter
          show-size
          v-model:value="file"
          accept=" application/pdf, application/zip, image/png, image/jpeg, text/plain,
          application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document,
          application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          truncate-length="15"
          label="Add File"
          @change="selectFile"
        >

        </v-file-input>

        <v-checkbox
          input-value="true"
          disabled
          :label="`Share with teacher`"
        ></v-checkbox>

        <v-checkbox
          disabled
          v-model="share"
          :label="`Share with students(not implemented)`"
        ></v-checkbox>

        <v-divider class="mt-2"></v-divider>

      </v-card-text>

      <v-card-actions>

        <v-btn
          dark
          v-on:click="setProgress"
          color="primary"
        > Upload
          <v-icon right dark>mdi-cloud-upload</v-icon>
        </v-btn>

      </v-card-actions>

      <v-progress-linear
        reactive
        v-bind:value="progress"

        color="green"
      ></v-progress-linear>

    </v-card>

    <v-card class="mx-auto my-4"
            max-width="500">
      <v-alert v-if="message" v-bind:type="alertType">
        {{ message }}
      </v-alert>
    </v-card>

  </div>

</template>

<script>

import UploadService from '../services/UploadFilesService'
import { fbInsertObject } from '@/plugins/dbOperations'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'SubmitAssignmentKeith',

  props: {
    module_code: {
      type: String,
      default: 'sx12'
    },
    student_id: {
      type: String,
      default: '475271'
    }

  },

  data () {
    return {
      alertType: 'info',
      file: undefined,
      loading: false,
      share: false,
      currentFile: undefined,
      progress: 0,
      message: '',
      timer: 0,
      fileInfos: []
    }
  },
  methods: {
    ...mapMutations(['saveUser']),
    ...mapActions(['']),
    ...mapGetters(['getUser']),

    selectFile (file) {
      this.progress = 0
      this.currentFile = file
    },

    upload () {
      this.alertType = 'success'

      // set file info keith
      this.currentFile.student_id = this.getUser().student_id

      UploadService.upload(this.currentFile, (event) => {
        // this.progress = Math.round((100 * event.loaded) / event.total)
        this.timer = 0
      })
        .then((response) => {
          this.message = response.data.message
          this.loading = false

          // create object
          const newAssignmentUpload = {}
          newAssignmentUpload.module_code = this.module_code
          newAssignmentUpload.filename = this.currentFile.name
          const submitTime = Date.now()
          newAssignmentUpload.submitTime = submitTime

          // enter in db keith
          fbInsertObject('students', 'student_id', this.getUser().student_id, 'submissions/' + submitTime, newAssignmentUpload)

          return UploadService.getFiles()
        })
        .then((files) => {
          this.fileInfos = files.data
          this.loading = false
          this.currentFile = undefined
          this.file = undefined
        })
        .catch(() => {
          this.message = "File too big"
          this.alertType = 'error'
          this.progress = 0
          this.currentFile = undefined
          this.loading = false
          this.file = undefined
        })
    },

    setProgress () {
      // this.progress = Math.round((100 * event.loaded) / event.total)
      this.message = ''

      if (!this.currentFile) {
        this.message = 'Please select a file!'
        this.alertType = 'warning'
        this.loading = false
        return
      }

      setTimeout(() => {
        if (this.timer === 100) {
          this.upload()
        } else {
          this.loading = true
          this.setProgress()
          this.timer++
          if (this.progress <= 100) {
            this.progress++
          }
        }
      }, 10)
    }

  },

  mounted () {
    UploadService.getFiles().then(response => {
      this.fileInfos = response.data
    })
  }

}
</script>

<style scoped>

</style>
