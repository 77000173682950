<template>
  <v-card

  >
    <v-toolbar
      color="primary"
      dark

    >

      <v-toolbar-title>{{ $t("Upcoming Exams") }}</v-toolbar-title>

      <v-spacer></v-spacer>
      <v-icon>mdi-alarm</v-icon>

    </v-toolbar>

    <v-list two-line max-height="400" class="overflow-auto">
      <v-list-item-group

        active-class="pink--text"
        multiple
      >

        <template>
          <v-list-item v-if="!items">No Exams for now : )</v-list-item>
          <v-list-item v-for="(email, index) in items" :key="index">

            <template v-slot:default="{ active }">
              <v-list-item-content>
                <v-list-item-title v-text="email.name"></v-list-item-title>

                <v-list-item-subtitle v-text="dateDateNow(email.time)"></v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-list-item-action-text v-text="timeDateNow(email.time)"></v-list-item-action-text>

              </v-list-item-action>
            </template>
          </v-list-item>

        </template>
      </v-list-item-group>

      <v-list-item>
        <v-spacer></v-spacer>

      </v-list-item>

    </v-list>

    <div>

    </div>

  </v-card>

</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { fbGetSubTreeWithKey } from '@/plugins/dbOperations'
import moment from 'moment'

export default {
  name: 'ExamsKeith',
  data () {
    return {

      items: [{
        subject_name: 'Organisation and IT',
        year_of_study: 1,
        date: '27 Jul, 13:00',
        icon: 'mdi-database'
      }, {
        subject_name: 'Requirements Engineering',
        year_of_study: 3,
        date: '29 Jul, 15:00',
        icon: 'mdi-contrast-circle'
      }, {
        subject_name: 'Requirements Engineering',
        year_of_study: 3,
        date: '29 Jul, 15:00',
        icon: 'mdi-contrast-circle'
      }
      ]

    }
  },
  methods: {
    ...mapMutations(['saveUser']),
    ...mapActions(['']),
    ...mapGetters(['getUser']),

    async getUpcomingExams () {
      const student = await fbGetSubTreeWithKey('students', 'student_id', this.getUser().student_id)
      this.items = student.upcoming_exams
    },
    dateDateNow (timestamp) {
      return moment(timestamp * 1000, 'x').format('ddd, DD MMM YYYY')
    },
    timeDateNow (timestamp) {
      return moment(timestamp * 1000, 'x').format('HH:mm')
    }

  },

  mounted: function () {
    this.getUpcomingExams()
  },

  computed: {}

}
</script>

<style scoped>

</style>
