<template>
  <div id="notesapp" @click="resetActive()">
    <saved-boards :boards="boards" :active-board-index="activeBoardIndex"></saved-boards>
    <div class="board-content">
      <div class="main_menu">

        <div class="note_actions">

          <!--          <button class="positive" @click="$refs.board.addNote('positive')">+</button>-->
          <!--          <button class="neutral" @click="$refs.board.addNote('neutral')">Neutral note</button>-->
          <!--          <button class="negative" @click="$refs.board.addNote('improvement')">Improvement note</button>-->

          <!--          <button @click="$refs.board.reArrange()"-->
          <!--                  title="Rearranges the notes by amount of votes and make them fit the current window">-->
          <!--            Re-arrange <sup class="beta">BETA</sup>-->
          <!--          </button>-->
        </div>
        <!--        <div class="board_actions">-->
        <div>
          <v-btn icon @click="$refs.board.addNote('positive')">
            <v-icon>mdi-book-plus</v-icon>
          </v-btn>
          <v-btn icon v-on:click="saveBoards" :disabled="!unsavedChanges">
            <v-icon> mdi-content-save</v-icon>
          </v-btn>
          <!--          <span class="subtle" :class="{'hidden':!unsavedChanges}">There are unsaved changes</span>-->
          <!--          <button v-on:click="saveBoards" :disabled="!unsavedChanges">Save</button>-->
          <button class="menu-toggle invert" @click.stop="toggleSidebar">☰</button>
        </div>
      </div>

      <board ref="board" :board="activeBoard" v-if="activeBoard"/>
    </div>
  </div>
</template>

<script>
import Board from './Board'
import SavedBoards from './SavedBoards'
import moment from 'moment'
import bus from './bus'
import { fbAddItem, fbGetSubTree, fbGetSubTreeWithKey, fbUpdateItem } from '@/plugins/dbOperations'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'NotesKeith',
  components: {
    Board,
    SavedBoards
  },

  data: function () {
    return {
      activeBoardIndex: 0,
      unsavedChanges: false,
      boards: []
    }
  },

  computed: {
    activeBoard () {
      return this.boards[this.activeBoardIndex] || false
    }
  },

  beforeMount () {
    const self = this

    bus.$on('remove-note', id => {
      const noteIndex = self.activeBoard.notes.findIndex(note => {
        return (id === note.id)
      })
      self.activeBoard.notes.splice(noteIndex, 1)
    })

    bus.$on('load-board', id => {
      self.activeBoardIndex = id
    })

    bus.$on('create-board', () => {
      self.boards.push(self.createBoard(false))
      self.activeBoardIndex = self.boards.length - 1
    })

    bus.$on('remove-board', id => {
      const last = self.boards.length - 1
      if (self.activeBoardIndex === last && id === last) {
        self.activeBoardIndex--
      }
      self.boards.splice(id, 1)
    })

    bus.$on('clear-board', () => {
      self.activeBoard.notes.splice(0, self.activeBoard.notes.length)
    })

    bus.$on('save-boards', () => {
      self.saveState()
    })
    bus.$on('export-board', () => {
      const board = new BoardExport(this.activeBoard)
      board.save()
    })
  },

  methods: {
    ...mapMutations(['saveUser']),
    ...mapActions(['']),
    ...mapGetters(['getUser']),

    resetActive () {
      bus.$emit('reset-active')
    },

    boardTitle () {
      const today = moment().format('LL')
      return `${today}`
    },

    saveBoards () {
      bus.$emit('save-boards')
    },

    createBoard (initial) {
      const board = {
        title: this.boardTitle(),
        notes: [],
        initial: initial
      }
      return board
    },

    toggleSidebar () {
      bus.$emit('toggle-sidebar')
    },

    // Loads recent config from localstorage
    async loadState () {
      const storage = window.localStorage

      // keith charm
      const student = await fbGetSubTreeWithKey('students', 'student_id', this.getUser().student_id)
      // this.boards = student.notes

      // Check if there is saved content available
      if (storage.getItem('retrospective-version') !== '1') {
        storage.setItem('retrospective-board', JSON.stringify(student.notes))
        this.migrateState()
      }

      const loadedContent = storage.getItem('retrospective-board')
      if (loadedContent) {
        this.boards = JSON.parse(loadedContent)
      }
    },

    // Saves current config to localstorage
    saveState () {
      const storage = window.localStorage
      const content = JSON.stringify(this.boards)
      storage.setItem('retrospective-board', content)
      this.unsavedChanges = false

      // keith charm
      fbUpdateItem('students', 'student_id', this.getUser().student_id, 'notes', this.boards)
    },

    migrateState () {
      const storage = window.localStorage

      let i = 0
      // Try to migrate data from older versions
      const oldState = storage.getItem('retrospective-board')

      // Data to migrate
      if (oldState) {
        let data = JSON.parse(oldState)
        data.forEach(board => {
          if (!board.notes) return
          board.notes.forEach(note => {
            // Check props for each note
            if (!note.id) {
              note.id = i
            }
            i++
          })
        })
        data = JSON.stringify(data)

        // Write updated item back to localStorage
        storage.setItem('retrospective-board', data)
      }

      // If migration succeeds, save version number in localStorage
      storage.setItem('retrospective-version', '1')
    }
  },

  watch: {

    $route: {
      handler: (to, from) => {
        document.title = to.meta.title || 'Your Website'
      },
      immediate: true
    },

    boards: {
      handler: function (newVal, oldVal) {
        // check if we're loading the app for the first time
        if (oldVal[0] && !oldVal[0].initial) {
          this.unsavedChanges = true
        }
      },
      deep: true // watch EVERYTHING
    }
  },

  created () {
    this.loadState()
    if (this.boards.length === 0) {
      this.boards.push(this.createBoard(true))
    }
  }

}
</script>

<style scoped>
@import '../notes/variables.scss';
@import '../notes/common.scss';

</style>
