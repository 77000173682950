<template>

  <v-card
    height="70%"
  >

    <v-toolbar
      color="primary"
      dark
    >
      <v-toolbar-title>{{ $t("Skills Passport") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>mdi-compass</v-icon>
      </v-btn>
    </v-toolbar>

    <div class="pt-16" id="chart"></div>

  </v-card>
</template>

<script>
import ApexCharts from 'apexcharts'

export default {
  name: 'Spider',

  methods: {
    loadChart () {
      const options = {
        chart: {
          type: 'radar',
          width: '140%',
          offsetX: -90,
          offsetY: -45,
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 1000,
            animateGradually: {
              enabled: true,
              delay: 150
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350
            }
          },
          toolbar: {
            show: false
          }
        },
        fill: {
          colors: ['gray']
        },
        dataLabels: {
          enabled: true,
          fill: {
            color: 'red'
          },
          style: {
            fontSize: '15px',
            colors: ['#039BE5']
          }
        },
        stroke: {
          show: true,
          colors: ['#40FFA7']
        },
        markers: {
          enabled: false,
          size: 0,
          hover: {
            size: 10
          },
          colors: '#15A563',
          opacity: 0.5
        },
        plotOptions: {
          radar: {
            polygons: {
              strokeColors: '',
              fill: {
                colors: ['#f8f8f8', '#fff']
              }
            }
          }

        },
        title: {
          subtitle: '487130'
        },
        series: [{
          name: 'Level',
          data: [3, 6, 9, 2, 9, 4, 8, 6, 9, 5]
        }],
        xaxis: {
          categories: ['English', 'Dutch', 'Presentation', 'ICT', 'Creative Technology', 'Communication', 'Onderhandelen', 'Conflict hank', 'Team Work', 'Kr. D'],
          labels: {
            style: {
              colors: ['#15A563', '#15A563', '#15A563', '#15A563', '#15A563', '#15A563', '#15A563', '#15A563', '#15A563', '#15A563'],
              fontSize: '15px'
            }
          }
        },
        yaxis: {
          min: 0,
          max: 10
        }

      }

      const chart = new ApexCharts(document.querySelector('#chart'), options)

      chart.render()
    }
  },
  mounted: function () {
    this.loadChart()
  }

}

</script>

<style scoped>

</style>
