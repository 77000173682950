<template>

  <v-container class="grey lighten-5">
    <v-row no-gutters>
      <v-col
        v-bind:lg="lg"
        v-bind:md="md"
        v-bind:sm="sm"
        class="mx-auto"
      >
        <v-card height="467">
          <SubjectsPreview></SubjectsPreview>
        </v-card>
      </v-col>

      <v-col
        v-bind:lg="lg"
        v-bind:md="md"
        v-bind:sm="sm"
        class="mx-auto"
      >
        <v-card height="467">
          <NewsListTuan></NewsListTuan>
        </v-card>
      </v-col>

      <v-col
        v-bind:lg="lg"
        v-bind:md="md"
        v-bind:sm="sm"
        class="mx-auto"
      >
        <v-card height="467">
          <GradesTest></GradesTest>
        </v-card>
      </v-col>

      <v-col
        v-bind:lg="lg"
        v-bind:md="md"
        v-bind:sm="sm"
        class="mx-auto"
      >
        <v-card>
          <ScheduleSefanja></ScheduleSefanja>
        </v-card>
      </v-col>
    </v-row>

    2nd row

    <v-row no-gutters>
      <v-spacer></v-spacer>

      <v-col
        v-bind:lg="lg"
        v-bind:md="md"
        v-bind:sm="sm"
        class="mx-auto"
      >
        <v-card height="467">
          <NewsListTuan></NewsListTuan>
        </v-card>
      </v-col>

      <v-col
        v-bind:lg="lg"
        v-bind:md="md"
        v-bind:sm="sm"
        class="mx-auto"
      >
        <v-card>
          <ExamScheduleSefanja></ExamScheduleSefanja>
        </v-card>
      </v-col>

      <v-col
        v-bind:lg="lg"
        v-bind:md="md"
        v-bind:sm="sm"
        class="mx-auto"
      >

      </v-col>

    </v-row>
  </v-container>

</template>

<script>
import NewsListTuan from '@/components/NewsListTuan'
import SubjectsPreview from '@/components/SubjectsPreview'
import ScheduleSefanja from '@/components/ScheduleSefanja'
import GradesTest from '@/components/GradesTest'
import ExamScheduleSefanja from '@/components/ExamScheduleSefanja'

export default {
  components: { NewsListTuan, SubjectsPreview, ScheduleSefanja, GradesTest, ExamScheduleSefanja },
  name: 'MainPage',
  data () {
    return {

      lg: 6,
      md: 9,
      sm: 12

    }
  }
}

</script>

<style scoped>

</style>
