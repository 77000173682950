<template>
  <div>
    <pdf
      v-for="i in numPages"
      :key="i"
      :src="src"
      :page="i"
      style="width: 40%"
    ></pdf>
  </div>
</template>

<script>
import pdf from 'vue-pdf'

const loadingTask = pdf.createLoadingTask('./assets/sample-test.pdf')

export default {
  name: 'pdfSefanja',
  components: {
    pdf
  },
  data () {
    return {
      src: loadingTask,
      numPages: undefined
    }
  },
  mounted () {
    this.src.promise.then(pdf => {
      this.numPages = pdf.numPages
    })
  }
}
</script>

<style scoped>

</style>
