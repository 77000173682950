export const translations = [{
  lang: 'en',
  name: 'English',
  flag: 'us',
  Welcome: 'Welcome',
  hello: 'hello',
  email: 'email',
  password: 'password',
  'Google it yourself': 'Google it yourself',
  'student portal': 'student portal',
  introduction: 'introduction',
  'Student number': 'Student number',
  Name: 'Name',
  'Personal email': 'Personal email',
  What: 'What',
  bye: 'bye',
  Login: 'Login',
  'where is your email': 'where is your email',
  'Saxion News': 'Saxion News',
  'Saxion Teacher': 'Saxion Teacher',
  Today: 'Today',
  'Skills Passport': 'Skills Passport',
  Phone: 'Phone',
  Address: 'Address',
  'Portal Language': 'Portal Language',
  Emails: 'Emails',
  Grades: 'Grades',
  Subject: 'Subject',
  News: 'News',
  Newspaper: 'Newspaper',
  'Good Afternoon': 'Good Afternoon',
  Subjects: 'Subjects',
  Account: 'Account',
  Contacts: 'Contacts',
  Weather: 'Weather',
  Notes: 'Notes',
  'User not found': 'User not found',
  'Welcome! Logging you in student portal..': 'Welcome! Logging you in student portal..',
  'Upcoming Exams': 'Upcoming Exams',
  Announcements: 'Announcements',
  Overall: 'Overall',
  Instructions: 'Instructions',
  'P2P Study': 'P2P Study',
  'Official results test Introduction to Programming': 'Official results test Introduction to Programming',
  'Course Manual': 'Course Manual',
  'Week 1': 'Week 1',
  'Week 2': 'Week 2',
  'Week 3': 'Week 3',
  'Week 4': 'Week 4',
  'Week 5': 'Week 5',
  'Week 6': 'Week 6',
  'Week 7': 'Week 7',
  'Week 8': 'Week 8',
  Materials: 'Materials',
  Videos: 'Videos',
  Exercises: 'Exercises',
  Homework: 'Homework',
  'Exercise 1': 'Exercise 1',
  'Exercise 2': 'Exercise 2',
  'Exercise 3': 'Exercise 3',
  'Homework 1': 'Homework 1',
  'Homework 2': 'Homework 2',
  Schedule: 'Schedule',
  Clear: 'Clear',
  'Student Portal': 'Student Portal',
  Prepare: 'Prepare',
  'Hello World!': 'Hello World!',
  'Installing Instruction': 'Installing Instruction',
  'How to write code': 'How to write code',
  'Ask your question here': 'Ask your question here',
  Variables: 'Variables',
  Methods: 'Methods',
  'If-els statements': 'If-els statements',
  'While Loop': 'While Loop',
  List: 'List',
  'Mapping Structure': 'Mapping Structure',
  'Cheat Sheet': 'Cheat Sheet',
  Remarks: 'Remarks',
  'Homework 3': 'Homework 3',
  Quizes: 'Quizes',
  'Sample Tests': 'Sample Tests',
  'Sample Test 1': 'Sample Test 1',
  'Sample Test 2': 'Sample Test 2',
  'Getting started': 'Getting started',
  'How to code Hello World': 'How to code Hello World',
  'Methods in Java': 'Methods in Java',
  'How to write methods': 'How to write methods',
  'If statement': 'If statement',
  'Else statement': 'Else statement',
  'For Loop': 'For Loop',
  'List in Java': 'List in Java',
  'Linked List': 'Linked List',
  HashMap: 'HashMap',
  Car: 'Car',
  Test: 'Test'
}, {
  lang: 'es',
  name: 'Spanish',
  flag: 'es',
  Welcome: 'Bienvenidos',
  hello: 'Hola',
  email: 'Email',
  password: 'contraseña',
  'Google it yourself': 'Google, Google',
  'student portal': 'Portal estudiantil',
  introduction: 'Introducción',
  'Student number': 'Número de estudiante',
  Name: 'Nombre',
  'Personal email': 'Email personal',
  What: 'Qué',
  bye: 'adiós',
  Login: 'Acceso',
  'where is your email': 'donde esta tu email',
  'Saxion News': 'Noticias Sajxion',
  'Saxion Teacher': 'Maestro de sajería',
  Today: 'Hoy',
  'Skills Passport': 'Pasaporte de habilidades',
  Phone: 'Teléfono',
  Address: 'Habla a',
  'Portal Language': 'Lenguaje portal',
  Emails: 'Correos electrónicos',
  Grades: 'Los grados',
  Subject: 'Sujeto',
  News: 'Noticias',
  Newspaper: 'Periódico',
  'Good Afternoon': 'Buenas tardes',
  Subjects: 'Asignaturas',
  Account: 'Cuenta',
  Contacts: 'Contactos',
  Weather: 'Tiempo',
  Notes: 'Notas',
  'User not found': 'Usuario no encontrado',
  'Welcome! Logging you in student portal..': '¡Bienvenidos! Logando en el portal de estudiantes.',
  'Upcoming Exams': 'Próximos exámenes',
  Announcements: 'Anuncios',
  Overall: 'General',
  Instructions: 'Instrucciones',
  'P2P Study': 'Estudio p2P',
  'Official results test Introduction to Programming': 'Prueba de resultados oficiales Introducción a la programación',
  'Course Manual': 'Manual del curso',
  'Week 1': 'Semana 1',
  'Week 2': 'Semana 2',
  'Week 3': 'Semana 3',
  'Week 4': 'Semana 4',
  'Week 5': 'Semana 5',
  'Week 6': 'Semana 6',
  'Week 7': 'Semana 7',
  'Week 8': 'Semana 8',
  Materials: 'Materiales',
  Videos: 'Videos',
  Exercises: 'Ejercicios',
  Homework: 'Tarea',
  'Exercise 1': 'Ejercicio 1',
  'Exercise 2': 'Ejercicio 2',
  'Exercise 3': 'Ejercicio 3',
  'Homework 1': 'Tarea 1',
  'Homework 2': 'Tarea 2',
  Schedule: 'Calendario',
  Clear: 'Claro',
  'Student Portal': 'Portal estudiantil',
  Prepare: 'Preparar',
  'Hello World!': '¡Hola Mundo!',
  'Installing Instruction': 'Instalación de instrucciones',
  'How to write code': 'Cómo escribir código',
  'Ask your question here': 'Haz tu pregunta aquí',
  Variables: 'Variables',
  Methods: 'Métodos',
  'If-els statements': 'Declaraciones de IF-ELS',
  'While Loop': 'Mientras que el bucle',
  List: 'Lista',
  'Mapping Structure': 'Estructura de mapeo',
  'Cheat Sheet': 'Hoja de trucos',
  Remarks: 'Comentarios',
  'Homework 3': 'Tarea 3',
  Quizes: 'Quitarse',
  'Sample Tests': 'Pruebas de muestra',
  'Sample Test 1': 'Prueba de muestra 1',
  'Sample Test 2': 'Prueba de muestra 2',
  'Getting started': 'Empezando',
  'How to code Hello World': 'Cómo Code Hello World',
  'Methods in Java': 'Métodos en Java',
  'How to write methods': 'Cómo escribir métodos',
  'If statement': 'Si la declaración',
  'Else statement': 'Otra declaración',
  'For Loop': 'En bucle',
  'List in Java': 'LISTA EN JAVA',
  'Linked List': 'Lista enlazada',
  HashMap: 'Hashmap',
  Car: 'Carro',
  Test: 'Prueba'
}, {
  lang: 'de',
  name: 'German',
  flag: 'de',
  Welcome: 'Herzlich willkommen',
  hello: 'Hallo',
  email: 'Email',
  password: 'Passwort',
  'Google it yourself': 'Google es selbst',
  'student portal': 'Studentenportal',
  introduction: 'Einführung',
  'Student number': 'Studentennummer.',
  Name: 'Name',
  'Personal email': 'Persönliche E-Mail',
  What: 'Was',
  bye: 'Tschüss',
  Login: 'Anmeldung',
  'where is your email': 'Wo ist deine E-Mail?',
  'Saxion News': 'Saxion News.',
  'Saxion Teacher': 'Saxiotlehrer.',
  Today: 'Heute',
  'Skills Passport': 'Kenntnissepass',
  Phone: 'Telefon',
  Address: 'Adresse',
  'Portal Language': 'Portalsprache.',
  Emails: 'E-Mails',
  Grades: 'Noten',
  Subject: 'Gegenstand',
  News: 'Nachrichten',
  Newspaper: 'Zeitung',
  'Good Afternoon': 'Guten Nachmittag',
  Subjects: 'Themen',
  Account: 'Konto',
  Contacts: 'Kontakte',
  Weather: 'Wetter',
  Notes: 'Anmerkungen',
  'User not found': 'Benutzer nicht gefunden',
  'Welcome! Logging you in student portal..': 'Herzlich willkommen! Protokollierung bei dem Studentenportal ..',
  'Upcoming Exams': 'Bevorstehende Prüfungen.',
  Announcements: 'Ankündigungen',
  Overall: 'Insgesamt',
  Instructions: 'Anleitung',
  'P2P Study': 'P2P-Studie.',
  'Official results test Introduction to Programming': 'Offizielle Ergebnisse testen Einführung in die Programmierung',
  'Course Manual': 'Kurshandbuch',
  'Week 1': 'Woche 1',
  'Week 2': 'Woche 2',
  'Week 3': 'Woche 3.',
  'Week 4': 'Woche 4.',
  'Week 5': 'Woche 5.',
  'Week 6': 'Woche 6.',
  'Week 7': 'Woche 7.',
  'Week 8': 'Woche 8.',
  Materials: 'Materialien',
  Videos: 'Videos',
  Exercises: 'Übungen',
  Homework: 'Hausaufgaben',
  'Exercise 1': 'Übung 1',
  'Exercise 2': 'Übung 2',
  'Exercise 3': 'Übung 3',
  'Homework 1': 'Hausaufgaben 1',
  'Homework 2': 'Hausaufgaben 2.',
  Schedule: 'Zeitplan',
  Clear: 'klar',
  'Student Portal': 'Studentenportal',
  Prepare: 'Bereiten',
  'Hello World!': 'Hallo Welt!',
  'Installing Instruction': 'Anweisung installieren.',
  'How to write code': 'Wie schreibe ich Code?',
  'Ask your question here': 'Fragen Sie Ihre Frage hier',
  Variables: 'Variablen',
  Methods: 'Methoden',
  'If-els statements': 'IF-ELS-Anweisungen',
  'While Loop': 'While-Schleife',
  List: 'Aufführen',
  'Mapping Structure': 'Kartierungsstruktur.',
  'Cheat Sheet': 'Schummelblatt',
  Remarks: 'Bemerkungen',
  'Homework 3': 'Hausaufgaben 3.',
  Quizes: 'Quizium',
  'Sample Tests': 'Beispieltests',
  'Sample Test 1': 'Beispieltest 1',
  'Sample Test 2': 'Beispieltest 2.',
  'Getting started': 'Einstieg',
  'How to code Hello World': 'So codieren Sie Hallo World',
  'Methods in Java': 'Methoden in Java.',
  'How to write methods': 'Wie schreibe ich Methoden?',
  'If statement': 'Wenn Erklärung',
  'Else statement': 'Else Anweisung.',
  'For Loop': 'Für Schleife',
  'List in Java': 'Liste in Java.',
  'Linked List': 'Verknüpfte Liste.',
  HashMap: 'Hashmap.',
  Car: 'Wagen',
  Test: 'Prüfung'
}, {
  lang: 'nl',
  name: 'Dutch',
  flag: 'nl',
  Welcome: 'Welkom',
  hello: 'Hallo',
  email: 'e-mail',
  password: 'wachtwoord',
  'Google it yourself': 'Google het zelf',
  'student portal': 'studenten Portaal',
  introduction: 'invoering',
  'Student number': 'Studenten nummer',
  Name: 'Naam',
  'Personal email': 'Persoonlijke email',
  What: 'Wat',
  bye: 'doei',
  Login: 'Log in',
  'where is your email': 'Waar is uw e-mail',
  'Saxion News': 'Saxion Nieuws',
  'Saxion Teacher': 'Saxion Leraar',
  Today: 'Vandaag',
  'Skills Passport': 'Vaardighedenpaspoort',
  Phone: 'Telefoon',
  Address: 'Adres',
  'Portal Language': 'Portal',
  Emails: 'E-mails',
  Grades: 'Rekken',
  Subject: 'Onderwerpen',
  News: 'Nieuws',
  Newspaper: 'Krant',
  'Good Afternoon': 'Goedenmiddag',
  Subjects: 'Onderwerpen',
  Account: 'Account',
  Contacts: 'Contacten',
  Weather: 'Weer',
  Notes: 'Opmerkingen',
  'User not found': 'Gebruiker niet gevonden',
  'Welcome! Logging you in student portal..': 'Welkom! Breng je in op Student Portal ..',
  'Upcoming Exams': 'Aankomende examens',
  Announcements: 'Aankondigingen',
  Overall: 'In het algemeen',
  Instructions: 'Instructies',
  'P2P Study': 'P2P-studie',
  'Official results test Introduction to Programming': 'Officiële resultaten Test Inleiding tot het programmeren',
  'Course Manual': 'Cursushandleiding',
  'Week 1': 'Week 1',
  'Week 2': 'Week 2',
  'Week 3': 'Week 3',
  'Week 4': 'Week 4',
  'Week 5': 'Week 5',
  'Week 6': 'Week 6',
  'Week 7': 'Week 7',
  'Week 8': 'Week 8',
  Materials: 'Materialen',
  Videos: 'Videos',
  Exercises: 'Opdrachten',
  Homework: 'Huiswerk',
  'Exercise 1': 'Oefening 1',
  'Exercise 2': 'Oefening 2',
  'Exercise 3': 'Oefening 3',
  'Homework 1': 'Huiswerk 1',
  'Homework 2': 'Huiswerk 2',
  Schedule: 'Schema',
  Clear: 'Doorzichtig',
  'Student Portal': 'Studenten Portaal',
  Prepare: 'Bereiden',
  'Hello World!': 'Hallo Wereld!',
  'Installing Instruction': 'Instructie installeren',
  'How to write code': 'Hoe code te schrijven',
  'Ask your question here': 'Vraag uw vraag hier',
  Variables: 'Variabelen',
  Methods: 'Methoden',
  'If-els statements': 'Als-els-verklaringen',
  'While Loop': 'Herhalingslus',
  List: 'Lijst',
  'Mapping Structure': 'Mappingstructuur',
  'Cheat Sheet': 'Spiek briefje',
  Remarks: 'Opmerkingen',
  'Homework 3': 'Huiswerk 3',
  Quizes: 'Schrijft',
  'Sample Tests': 'Voorbeeldtests',
  'Sample Test 1': 'Voorbeeldtest 1',
  'Sample Test 2': 'Voorbeeldtest 2',
  'Getting started': 'Beginnen',
  'How to code Hello World': 'Hoe codeer je hallo wereld',
  'Methods in Java': 'Methoden in Java',
  'How to write methods': 'Hoe methoden te schrijven',
  'If statement': 'If-statement',
  'Else statement': 'Anders verklaring',
  'For Loop': 'For loop',
  'List in Java': 'Lijst in Java',
  'Linked List': 'Gelinkte lijst',
  HashMap: 'Hash kaart',
  Car: 'Auto',
  Test: 'Test'
}, {
  lang: 'fr',
  name: 'French',
  flag: 'fr',
  Welcome: 'Bienvenue',
  hello: 'Bonjour',
  email: 'e-mail',
  password: 'le mot de passe',
  'Google it yourself': 'Google vous-même',
  'student portal': 'portail étudiant',
  introduction: 'introduction',
  'Student number': "Numéro d'étudiant",
  Name: 'Nom',
  'Personal email': 'Email personnel',
  What: 'Quoi',
  bye: 'au revoir',
  Login: 'Connexion',
  'where is your email': 'Où est votre email',
  'Saxion News': 'Nouvelles Saxion',
  'Saxion Teacher': 'Professeur saxion',
  Today: "Aujourd'hui",
  'Skills Passport': 'Passeport de compétences',
  Phone: 'Téléphoner',
  Address: 'Adresse',
  'Portal Language': 'Langue portale',
  Emails: 'Courriels',
  Grades: 'Grades',
  Subject: 'Matière',
  News: 'Nouvelles',
  Newspaper: 'Journal',
  'Good Afternoon': 'Bonne après-midi',
  Subjects: 'Sujets',
  Account: 'Compte',
  Contacts: 'Contacts',
  Weather: 'La météo',
  Notes: 'Remarques',
  'User not found': 'Utilisateur non trouvé',
  'Welcome! Logging you in student portal..': 'Bienvenue! Vous connecter dans le portail étudiant ..',
  'Upcoming Exams': 'Examens à venir',
  Announcements: 'Annonces',
  Overall: 'Globalement',
  Instructions: 'Instructions',
  'P2P Study': 'Étude P2P',
  'Official results test Introduction to Programming': 'Résultats officiels Test Introduction à la programmation',
  'Course Manual': 'Manuel de cours',
  'Week 1': 'Semaine 1',
  'Week 2': 'Semaine 2',
  'Week 3': 'Semaine 3',
  'Week 4': 'Semaine 4',
  'Week 5': 'Semaine 5',
  'Week 6': 'Semaine 6',
  'Week 7': 'Semaine 7',
  'Week 8': 'Semaine 8',
  Materials: 'Matériaux',
  Videos: 'Vidéos',
  Exercises: 'Des exercices',
  Homework: 'Devoirs',
  'Exercise 1': 'Exercice 1',
  'Exercise 2': 'Exercice 2',
  'Exercise 3': 'Exercice 3',
  'Homework 1': 'Devoirs 1',
  'Homework 2': 'Devoir 2',
  Schedule: 'Horaire',
  Clear: 'Dégager',
  'Student Portal': 'Portail étudiant',
  Prepare: 'Préparer',
  'Hello World!': 'Bonjour le monde!',
  'Installing Instruction': "Instruction d'installation",
  'How to write code': 'Comment écrire du code',
  'Ask your question here': 'Posez votre question ici',
  Variables: 'Variables',
  Methods: 'Méthodes',
  'If-els statements': 'Déclarations if-els',
  'While Loop': 'Tandis que la boucle',
  List: 'Lister',
  'Mapping Structure': 'Structure de cartographie',
  'Cheat Sheet': 'Feuille de triche',
  Remarks: 'Remarques',
  'Homework 3': 'Devoir 3',
  Quizes: 'Destitutions',
  'Sample Tests': 'Échantillons de tests',
  'Sample Test 1': "Test d'échantillon 1",
  'Sample Test 2': 'Exemple de test 2',
  'Getting started': 'Commencer',
  'How to code Hello World': 'Comment coder bonjour monde',
  'Methods in Java': 'Méthodes en Java',
  'How to write methods': 'Comment écrire des méthodes',
  'If statement': 'Si déclaration',
  'Else statement': 'Autre déclaration',
  'For Loop': 'Pour la boucle',
  'List in Java': 'Liste dans Java',
  'Linked List': 'Liste liée',
  HashMap: 'Hashmap',
  Car: 'Voiture',
  Test: 'Test'
}, {
  lang: 'ko',
  name: 'Korean',
  flag: 'kr',
  Welcome: '어서 오십시오',
  hello: '안녕하세요',
  email: '이메일',
  password: '암호',
  'Google it yourself': 'Google It.',
  'student portal': '학생 포털',
  introduction: '소개',
  'Student number': '학생 수',
  Name: '이름',
  'Personal email': '개인 이메일',
  What: '뭐',
  bye: '안녕',
  Login: '로그인',
  'where is your email': '귀하의 이메일은 어디에 있습니까?',
  'Saxion News': 'Saxion News.',
  'Saxion Teacher': '수영 선생님',
  Today: '오늘',
  'Skills Passport': '기술 여권',
  Phone: '전화',
  Address: '주소',
  'Portal Language': '포털 언어',
  Emails: '이메일',
  Grades: '성적',
  Subject: '제목',
  News: '뉴스',
  Newspaper: '신문',
  'Good Afternoon': '안녕하세요',
  Subjects: '주제',
  Account: '계정',
  Contacts: '콘택트 렌즈',
  Weather: '날씨',
  Notes: '메모',
  'User not found': '사용자가 없습니다',
  'Welcome! Logging you in student portal..': '어서 오십시오! 학생 포털에 로그인하기 ..',
  'Upcoming Exams': '다가오는 시험',
  Announcements: '공지 사항',
  Overall: '사무용 겉옷',
  Instructions: '명령',
  'P2P Study': 'P2P 연구',
  'Official results test Introduction to Programming': '프로그래밍에 대한 공식 결과 테스트 소개',
  'Course Manual': '코스 매뉴얼',
  'Week 1': '주 1 주',
  'Week 2': '주 2 주',
  'Week 3': '주 3 주',
  'Week 4': '주 4 주',
  'Week 5': '주 5 주',
  'Week 6': '주 6.',
  'Week 7': '주 7.',
  'Week 8': '주 8 주',
  Materials: '기재',
  Videos: '비디오',
  Exercises: '수업 과정',
  Homework: '숙제',
  'Exercise 1': '연습 1',
  'Exercise 2': '운동 2.',
  'Exercise 3': '운동 3.',
  'Homework 1': '숙제 1.',
  'Homework 2': '숙제 2.',
  Schedule: '시간표',
  Clear: '맑은',
  'Student Portal': '학생 포털',
  Prepare: '준비하다',
  'Hello World!': '안녕하세요!',
  'Installing Instruction': '지침 설치',
  'How to write code': '코드를 작성하는 방법',
  'Ask your question here': '여기에 귀하의 질문을하십시오',
  Variables: '변수',
  Methods: '행동 양식',
  'If-els statements': 'IF-ELS 진술',
  'While Loop': '루프',
  List: '명부',
  'Mapping Structure': '매핑 구조',
  'Cheat Sheet': '컨닝 지',
  Remarks: '발언',
  'Homework 3': '숙제 3.',
  Quizes: '정밀하다',
  'Sample Tests': '샘플 테스트',
  'Sample Test 1': '샘플 테스트 1.',
  'Sample Test 2': '샘플 테스트 2.',
  'Getting started': '시작하기',
  'How to code Hello World': 'Hello World를 코딩하는 방법',
  'Methods in Java': 'java의 메소드',
  'How to write methods': '방법을 작성하는 방법',
  'If statement': '만약',
  'Else statement': 'else 문',
  'For Loop': '루프 용',
  'List in Java': 'java의 목록',
  'Linked List': '링크 된 목록',
  HashMap: '해시 맵',
  Car: '차',
  Test: '테스트'
}]

export default { translations }
