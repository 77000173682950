<template>

  <v-container>

    <v-row no-gutters>

      <!--      right side of page, second last col-->
      <v-col
        md="4"
        v-bind:sm="sm"
      >

        <v-row no-gutters>

          <v-col
            md="12"
            v-bind:sm="sm"
            v-bind:class="colClass"
          >
            <HelloKeith></HelloKeith>
          </v-col>

          <v-col
            md="12"
            v-bind:sm="sm"
            v-bind:class="colClass"
          >
            <WeatherKeith2></WeatherKeith2>
          </v-col>

          <v-col
            md="12"
            v-bind:sm="sm"
            v-bind:class="colClass"
          >
            <ExamsKeith></ExamsKeith>
          </v-col>

          <v-col
            md="12"
            v-bind:sm="sm"
            v-bind:class="colClass"
          >
            <GradesTest></GradesTest>
          </v-col>

          <v-col
            md="12"
            v-bind:sm="sm"
            v-bind:class="colClass"
          >

            <ScheduleKeith></ScheduleKeith>

          </v-col>

          <v-col
            md="12"
            v-bind:sm="sm"
            v-bind:class="colClass"
          >

          </v-col>

        </v-row>
      </v-col>


      <!--      left side of page-->
      <v-col
        md="8"
        v-bind:sm="sm"
      >

        <v-row no-gutters>
          <v-col
            md="6"
            v-bind:sm="sm"
            v-bind:class="colClass">

            <SubjectsPreview></SubjectsPreview>
          </v-col>
          <v-col
            md="6"
            v-bind:sm="sm"
            v-bind:class="colClass"
          >
            <EmailPreviewKeith></EmailPreviewKeith>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col
            md="6"
            v-bind:sm="sm"
            v-bind:class="colClass"

          >
            <Searchboxkeith></Searchboxkeith>
          </v-col>

          <v-col
            md="6"
            v-bind:sm="sm"
            v-bind:class="colClass"
          >
            <NotesListKeith></NotesListKeith>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col
            md="6"
            v-bind:sm="sm"
            v-bind:class="colClass">
          </v-col>
          <v-col
            md="12"
            v-bind:sm="sm"
            v-bind:class="colClass">
            <SpiderKeith></SpiderKeith>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col
            md="12"
            v-bind:sm="sm"
            v-bind:class="colClass">
            <NewsListTuanMainPage></NewsListTuanMainPage>

          </v-col>
        </v-row>

      </v-col>


    </v-row>
  </v-container>

</template>

<script>
import SubjectsPreview from '@/components/SubjectsPreview'
import ScheduleKeith from '@/components/ScheduleKeith'
import EmailPreviewKeith from '@/components/EmailPreviewKeith'
import GradesTest from '@/components/GradesTest'
import WeatherKeith2 from '@/components/WeatherKeith2'
import Searchboxkeith from '@/components/Searchboxkeith'
import SpiderKeith from '@/components/SpiderKeith'
import HelloKeith from '@/components/HelloKeith'
import StudentProfileKeith from '@/components/StudentProfileKeith'
import NotesListKeith from '@/components/NotesListKeith'
import NewsListTuanMainPage from '@/components/NewsListTuanMainPage'
import ExamsKeith from '@/components/ExamsKeith'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import GradesSefanja from '@/components/GradesSefanja'

export default {
  components: {
    GradesSefanja,
    StudentProfileKeith,
    NotesListKeith,
    NewsListTuanMainPage,
    HelloKeith,
    Searchboxkeith,
    SpiderKeith,
    WeatherKeith2,
    SubjectsPreview,
    ScheduleKeith,
    EmailPreviewKeith,
    GradesTest,
    ExamsKeith
  },
  name: 'MainPage',
  data () {
    return {
      cols: ['', '', '', '', '', '', '', ''],

      cardHeight: undefined,
      lg: 4,
      md: undefined,
      sm: 12,
      colClass: 'pa-2'

    }
  },
  methods: {
    ...mapMutations(['saveUser']),
    ...mapActions(['']),
    ...mapGetters(['getUser'])

  },

  watch: {
    $route: {
      handler: (to, from) => {
        document.title = to.meta.title || 'Your Website'
      },
      immediate: true
    }
  }
}

</script>

<style scoped>

</style>
