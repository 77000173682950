<template>
  <div>
    <v-card
      class="mx-auto my-4"
      max-width="344"
      outlined
    >
      <v-card-title class="mb-n6">
        <span class="text-h6 font-weight-light">Grades</span>
      </v-card-title>

      <div class="ml-6">
        <v-breadcrumbs
          :items="items"
          divider="-"
        ></v-breadcrumbs>
      </div>

      <!--bar-->
      <v-progress-linear
        v-model="skill"
        color="blue-grey"
        height="25"
      >
        <template v-slot:default="{ value }">
          <strong>{{ Math.ceil(value) }}%</strong>
        </template>
      </v-progress-linear>

      <v-simple-table dense>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">
              Subject
            </th>
            <th class="text-left">
              Grade
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="item in grades"
            :key="item.subject"
          >
            <td>{{ item.subject }}</td>
            <td>{{ item.grade }}</td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>

    </v-card>
  </div>
</template>

<script>
import firebase from 'firebase/app'

export default {
  name: 'GradesSefanja',
  data () {
    return {

      skill: 20,

      grades: [
        {
          subject: 'Introduction to programming',
          grade: 8
        },
        {
          subject: 'Website Technology',
          grade: 6
        },
        {
          subject: 'Concurrency',
          grade: 9
        }
      ],
      items: [
        {
          text: 'Year 1',
          disabled: false,
          href: 'year1'
        },
        {
          text: 'Year 2',
          disabled: false,
          href: 'breadcrumbs_link_1'
        },
        {
          text: 'Year 3',
          disabled: true,
          href: 'breadcrumbs_link_2'
        },
        {
          text: 'Year 4',
          disabled: true,
          href: 'breadcrumbs_link_2'
        }
      ],
      db: firebase.database()
    }
  },
  methods: {
    getData () {
      fetch(
        'http://localhost:3000/sefanjagrades',
        {
          method: 'get'
        }
      )
        .then((response) => {
          return response.json()
        })
        .then((jsonData) => {
          this.grades = jsonData
        })
    },
    getNewData () {
      const getGrade = this.db.ref('grades')
      getGrade.on('value', (snapshot) => {
        const data = snapshot.val()
        this.grades = data
      })
    }
  },
  mounted: function () {
    this.getNewData()
  }
}

</script>

<style scoped>

</style>
