import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import {i18n} from './plugins/i18n'
import FlagIcon from 'vue-flag-icon'

import dbOps from './plugins/dbOperations'
import VueDraggable from './components/notes/draggable'

Vue.use(FlagIcon)
Vue.directive('draggable', VueDraggable)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  dbOps,
  i18n,
  render: h => h(App)
}).$mount('#app')
