<template>

  <div>

    <v-card
      class="mx-auto my-4"
      max-width="500"
      outlined v-if="!submissions"
    >
      <v-toolbar dark color="primary" flat height="40">

        <v-card-title class="body-1"></v-card-title>
        <v-spacer></v-spacer>
        <v-card-title class="body-2 text--white"></v-card-title>
      </v-toolbar>
      <v-card-title class="justify-center">
        You have not submitted any <br> assignment for this module yet : /
      </v-card-title>
    </v-card>

    <div v-for="(file,index) in submissions" :key="index">

      <v-card
        class="mx-auto my-4"
        max-width="500"
        outlined
        v-if="file.module_code === module_code"
      >

        <v-toolbar dark color="primary" flat height="40">
          <v-card-title class="body-1">Submitted: {{ timeDateNow(file.submitTime) }}</v-card-title>
          <v-spacer></v-spacer>
          <v-card-title class="body-2 text--white">{{ module_code }}</v-card-title>
        </v-toolbar>

        <v-card-title>

          {{ file.filename }}
          <v-spacer></v-spacer>
          <span class="body-2" v-if="file.grade">Grade:

       <v-progress-circular
         :rotate="180"
         size="40"
         width="10"
         :value="file.grade*10"
         v-bind:color="setGradeColor(file.grade)"
       >
      {{ file.grade }}
    </v-progress-circular>
      </span>
          <span class="body-2" v-if="!file.grade">Grade: to be graded</span>
        </v-card-title>
        <v-card-subtitle></v-card-subtitle>
        <v-card-text>

          <span class="text--accent-2"> Feedback:</span>
          <br> <span class="font-italic font-weight-bold" v-if="file.feedback">"{{ file.feedback }}"</span>

          <v-divider class="mt-2"></v-divider>

        </v-card-text>
        <v-card-actions>

          <v-btn
            text
            v-bind:href="backendServer+'files/'+ file.filename"
            color="primary"
          >
            Download
            <v-icon right>mdi-cloud-download</v-icon>
          </v-btn>

        </v-card-actions>

        <v-progress-linear
          reactive
          color="green"
        ></v-progress-linear>

      </v-card>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import {db} from '@/plugins/dbOperations'
import {mapActions, mapGetters, mapMutations} from 'vuex'


export default {
  name: 'DownloadAssignmentKeith',

  props: {
    module_code: {
      type: String,
      default: 'sx12'
    },
    module_name: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      submissions: [{

        module_code: 'sx12',
        filename: 'xyojjema.png',
        submitTime: '1624507662872',
        feedback: 'good job tuan',
        grade: 7
      }, {
        module_code: 'sx14',
        filename: 'asdf.pdf',
        submitTime: '1624607416872',
        feedback: 'good work sefanja',
        grade: 9
      }, {
        module_code: 'sx14',
        filename: 'asdf.pdf',
        submitTime: '1624607261872'
      }, {
        module_code: 'sx14',
        filename: 'asdf.pdf',
        submitTime: '1624607261872',
        feedback: 'good work keith',
        grade: 5

      }]

    }
  },

  methods: {
    ...mapMutations(['saveUser']),
    ...mapActions(['']),
    ...mapGetters(['getUser']),

    timeDateNow(timestamp) {
      return moment(timestamp, 'x').fromNow()
    },
    setGradeColor(grade) {
      if (grade <= 5) {
        return 'red'
      }
      if (grade >= 6 && grade < 8) {
        return 'orange'
      }
      if (grade >= 8) {
        return 'green'
      }
    },

    async getDbFilesList() {
      let myDbData
      await db.ref('students').on('value', (snapshot) => {
        snapshot.forEach((childSnapshot) => {
          const childData = childSnapshot.val()
          if (childData.student_id === this.getUser().student_id) {
            myDbData = childSnapshot.val()
            this.submissions = myDbData.submissions
            return myDbData
          }
        })
      })
      return myDbData
    },


  },

  mounted: function () {
    this.getDbFilesList()

  },
  computed: {
    backendServer() {
      return process.env.VUE_APP_BACKEND_SERVER
    }
  }

}
</script>

<style scoped>

</style>
