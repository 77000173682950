<template class="entirePage">

  <v-container class="page my-4">

    <!--    1st row-->
    <v-layout row wrap>
      <v-flex xs12 md4 pa-1>
        <v-card>
          <SubjectsPreview></SubjectsPreview>
        </v-card>
      </v-flex>

      <v-flex xs12 md4 pa-1>
        <v-card class="pa-1">
          <HelloKeith></HelloKeith>
        </v-card>
        <v-card height="375">
          <GradesTest></GradesTest>
        </v-card>
      </v-flex>

      <v-flex xs12 md4 pa-1>

        <v-card>
          <WeatherTest></WeatherTest>
        </v-card>
      </v-flex>

    </v-layout>

    <!--    2nd row-->
    <v-layout row wrap>
      <v-flex xs12 md4 pa-1>
        <v-card>
          <Searchboxkeith></Searchboxkeith>
        </v-card>
      </v-flex>

      <v-flex xs12 md4 pa-1>
        <v-card>
          <ScheduleSefanja></ScheduleSefanja>
        </v-card>
      </v-flex>

      <v-flex xs12 md4 pa-1>
        <v-card height="463">
          <EmailPreviewKeith></EmailPreviewKeith>
        </v-card>
      </v-flex>
    </v-layout>

    <!--    3rd row-->
    <v-layout row wrap justify-center>
      <v-flex xs12 md4 pa-1>
        <v-card>
          <Spider></Spider>
        </v-card>
      </v-flex>

      <v-flex xs12 md4 pa-1>
        <v-card>
          <ExamScheduleSefanja></ExamScheduleSefanja>
        </v-card>
      </v-flex>

    </v-layout>

  </v-container>

</template>

<script>
import NewsListTuan from '@/components/NewsListTuan'
import SubjectsPreview from '@/components/SubjectsPreview'
import ScheduleSefanja from '@/components/ScheduleSefanja'
import EmailPreviewKeith from '@/components/EmailPreviewKeith'
import GradesTest from '@/components/GradesTest'
import ExamScheduleSefanja from '@/components/ExamScheduleSefanja'
import Searchboxkeith from '@/components/Searchboxkeith'
import HelloKeith from '@/components/HelloKeith'
import WeatherKeith2 from '@/components/WeatherKeith2'
import WeatherTest from '@/components/WeatherTest'
import Spider from '@/components/Spider'

export default {
  components: {
    NewsListTuan,
    SubjectsPreview,
    ScheduleSefanja,
    EmailPreviewKeith,
    GradesTest,
    ExamScheduleSefanja,
    Searchboxkeith,
    HelloKeith,
    WeatherKeith2,
    WeatherTest,
    Spider
  },
  name: 'MainPageSefanja',
  data () {
    return {

      lg: 6,
      md: 9,
      sm: 12

    }
  }
}

</script>

<style scoped>
.entirePage {
  background: url('../assets/background.jpg');
  background-size: cover;
}
</style>
