<template>

  <div>
    <v-card
      class="mx-auto my-4"
      max-width="500"
    >
      <v-form>

        <v-img
          class="white--text align-end"
          src="https://vepa.nl/wp-content/uploads/2019/09/saxion_enschede_03.jpg"
        >
          <v-card-title class="">
            <v-icon
              left color="white"
            >
              mdi-account-key
            </v-icon>
            {{ $t("Login") }}
          </v-card-title>
        </v-img>

        <v-card-text>

          <v-text-field
            ref="email"
            v-model="email"
            :rules="[rules.email.required]"
            :error-messages="$t(errorMessages)"
            label="Email"
            hint="Student id"
            placeholder="489549"
            required
            light
            suffix="@student.saxion.nl"
            prepend-icon=" mdi-account-circle"
          ></v-text-field>

          <v-text-field
            class="my-2"
            ref="password"
            v-model="password"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[$t(rules.password.required), rules.password.min]"
            :type="show1 ? 'text' : 'password'"
            name="input-10-1"
            label="Password"
            hint="At least 4 characters"
            counter
            @click:append="show1 = !show1"
            prepend-icon=" mdi-lock"
          ></v-text-field>

        </v-card-text>
        <v-divider class="mt-2"></v-divider>
        <v-card-actions>

          <v-spacer></v-spacer>
          <v-slide-x-reverse-transition>
            <v-tooltip
              v-if="formHasErrors"
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="my-0"
                  v-bind="attrs"
                  @click="resetForm"
                  v-on="on"
                >
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("Clear") }}</span>
            </v-tooltip>
          </v-slide-x-reverse-transition>

          <v-btn
            color="primary"

            @click="submit"
          >
            {{ $t("Login") }}
          </v-btn>

        </v-card-actions>
        <v-progress-linear
          v-if="loading"
          indeterminate
          color="green"
        ></v-progress-linear>

      </v-form>

    </v-card>

    <v-card class="mx-auto my-4"
            max-width="500">
      <v-alert v-bind:type="alertType" v-if="showLoginMessage">
        {{ $t(loginMessage) }}
      </v-alert>
    </v-card>

    <div class="text-center">
      <p>Sample logins:</p>
      <p>485265 / 5072</p>
      <p>474876 / 4953</p>
      <p>485990 / 7727</p>
    </div>

  </div>

</template>

<script>
import { fbGetSubTreeWithKey } from '../plugins/dbOperations'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { i18n } from '@/plugins/i18n'

export default {
  name: 'Loginkeith',
  data: () => ({
    loading: false,
    alertType: 'info',
    errorMessages: '',
    email: '475271',
    showLoginMessage: false,
    formHasErrors: false,
    show1: false,
    show2: true,
    show3: false,
    show4: false,
    password: '2177',
    loginMessage: '',
    rules: {
      email: { required: v => !!v || 'where is your email' },
      password: {
        required: v => !!v || 'where is your password',
        min: v => v.length >= 4 || 'Min 4 characters'
      }
    },
    student_users: [{
      full_name: 'boi boi',
      first_name: 'boi',
      last_name: 'boi',
      email: 'boi@student.saxion.nl',
      code: 'boi344',
      role: 'student',
      password: 1234
    }
    ]

  }),

  computed: {
    form () {
      return {
        email: this.email,
        password: this.password
      }
    }
  },

  watch: {
    name () {
      this.errorMessages = ''
    },
    $route: {
      handler: (to, from) => {
        document.title = to.meta.title || 'Your Website'
      },
      immediate: true
    }
  },

  methods: {
    ...mapMutations(['saveUser']),
    ...mapActions(['']),
    ...mapGetters(['getUser']),

    addressCheck () {
      this.errorMessages = this.address && !this.name
        ? 'Hey! I\'m required'
        : ''

      return true
    },
    resetForm () {
      this.errorMessages = []
      this.formHasErrors = false

      Object.keys(this.form).forEach(f => {
        this.$refs[f].reset()
      })
    },
    submit () {
      this.showLoginMessage = false
      this.formHasErrors = false
      Object.keys(this.form).forEach(f => {
        if (!this.$refs[f].validate()) {
          this.formHasErrors = true
        }
        this.$refs[f].validate(true)
      })

      if (!this.formHasErrors) {
        this.loading = true
        setTimeout(() => {
          this.logon(this.form.email, this.form.password)
        }, 1000)
      }
    },
    async logon (givenEmail, givenPassword) {
      givenEmail = givenEmail.trim() + '@student.saxion.nl'

      // replace sample students list from db
      const student = await fbGetSubTreeWithKey('students', 'email', givenEmail)

      if (student && student.password === givenPassword) {
        i18n.locale = student.language_pref
        this.loginMessage = 'Welcome! Logging you in student portal..'
        this.showLoginMessage = true
        this.alertType = 'success'

        setTimeout(() => {
          student.profile_pic = 'https://randomuser.me/api/portraits/women/' + student.profile_pic + '.jpg'

          // remove pass from student
          // delete student.password
          this.saveUser(student)

          localStorage.setItem('user', JSON.stringify(student))
          this.$router.push('portal') // redirect
          this.loading = false
        }, 2000)
      } else {
        this.loading = false
        this.showLoginMessage = true
        this.loginMessage = 'Invalid password'
        this.alertType = 'warning'
      }
    }

  }

}
</script>

<style scoped>

</style>
