<template>
  <v-card
  >

    <v-toolbar
      color="primary"
      dark>
      <v-toolbar-title>{{ $t("Weather") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn text v-on:click="getWeather" icon>
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </v-toolbar>

    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="text-h5">
          {{ weather.city_name }}
        </v-list-item-title>
        <v-list-item-subtitle>{{ weather.weather.description }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-spacer></v-spacer>

    </v-list-item>

    <v-card-text>
      <v-row align="center">
        <v-col
          class="text-h3"
          cols="6"
        >
          {{ weather.temp }} &deg;C
        </v-col>
        <v-col cols="6">
          <v-img
            v-bind:src='"https://www.weatherbit.io/static/img/icons/"+weather.weather.icon+".png"'
            alt="Sunny image"
            max-width="128px"
            class="mx-auto"
          ></v-img>
        </v-col>
      </v-row>
    </v-card-text>

    <v-list-item>
      <v-list-item-icon>
        <v-icon>mdi-send</v-icon>
      </v-list-item-icon>
      <v-list-item-subtitle>{{ weather.wind_spd }} km/h Wind speed</v-list-item-subtitle>
    </v-list-item>

    <v-list-item>
      <v-list-item-icon>
        <v-icon>mdi-cloud-download</v-icon>
      </v-list-item-icon>
      <v-list-item-subtitle>{{ weather.rh }}% Humidity</v-list-item-subtitle>
    </v-list-item>

  </v-card>
</template>

<script>
import moment from 'moment'

export default {
  name: 'WeatherKeith',
  data () {
    return {
      weather:
        {
          wind_cdir: 'NE',
          rh: 59,
          pod: 'd',
          lon: '-78.63861',
          pres: 1006.6,
          timezone: 'America New_York',
          ob_time: '2017-08-28 16:45',
          country_code: 'US',
          clouds: 75,
          vis: 10,
          wind_spd: 6,
          wind_cdir_full: 'northeast',
          app_temp: 24.25,
          state_code: 'NC',
          ts: 1503936000,
          h_angle: 0,
          dewpt: 15.65,
          weather: {
            icon: 'c03d',
            code: 803,
            description: 'Broken clouds'
          },
          uv: 2,
          aqi: 45,
          station: 'CMVN7',
          wind_dir: 50,
          elev_angle: 63,
          datetime: '2017-08-28:17',
          precip: 0,
          ghi: 444.4,
          dni: 500,
          dhi: 120,
          solar_rad: 350,
          city_name: 'Raleigh',
          sunrise: '10:44',
          sunset: '23:47',
          temp: 24,
          lat: '35.7721',
          slp: 1022.2
        },

      userLat: 52.253432399999994,
      userLong: 6.1729635

    }
  },
  methods: {
    getLocation () {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.showPosition)
      } else {
        alert('Geolocation is not supported by this browser.')
      }
    },

    showPosition (position) {
      this.userLat = position.coords.latitude
      this.userLong = position.coords.longitude
    },

    getWeather () {
      // get location
      this.getLocation()

      // get weather
      const openWeatherApi = `https://api.weatherbit.io/v2.0/current?lat=${this.userLat}&lon=${this.userLong}&key=7bc5f834810c4fe8965ccc747913f453`

      fetch(
        openWeatherApi,
        {
          method: 'get'
        }
      )
        .then((response) => {
          return response.json()
        })
        .then((jsonData) => {
          jsonData.data[0].temp = Math.round(jsonData.data[0].temp)
          jsonData.data[0].rh = Math.round(jsonData.data[0].rh)
          jsonData.data[0].wind_spd = Math.round(jsonData.data[0].wind_spd)
          jsonData = jsonData.data[0]
          this.weather = jsonData
        })
    }

  },

  computed: {
    currentTime () {
      return moment(this.weather.ts, 'X').format('ddd hh:mm')
    }
  },

  mounted: function () {
    this.getWeather()
  }
}
</script>

<style scoped>

</style>
