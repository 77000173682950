<template>
  <div>

    <div class="videoWrapper">
      <!-- Copy & Pasted from YouTube -->

      <iframe  v-bind:src="videoUrl" title="video player" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen></iframe>
    </div>


  </div>
</template>

<script>

export default {
  name: 'VideoPlayerComponentKeith',
  props: {
    // type check plus other validations
    videoId: {
      type: String

    }
  },
  data () {
    return {
      site: 'https://www.youtube.com/embed/'
    }
  },

  methods: {},
  computed: {
    videoUrl: function () {
      const url = this.site + this.videoId
      return url
    }

  }

}
</script>

<style scoped>

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

</style>
