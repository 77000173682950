<template>
  <div>

    <v-card
    >
      <v-toolbar
        color="primary"
        dark
      >
        <v-toolbar-title>{{ $t("News") }}</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-icon>mdi-newspaper</v-icon>

      </v-toolbar>

      <v-list dense>

        <v-list-item-group
          v-model="selectedItem"
          color="primary"
        >
          <v-list-item
            class="text-h7" v-for="(newsItem,index) in newsItems" v-bind:key="index"
          >

            <router-link v-bind:to="{params: { id:  newsItem.slug }}"> {{ newsItem.title }}</router-link>

          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
  </div>
</template>

<script>

export default {
  name: 'NewsListTuan',
  props: {
    newsItems: {
      type: Array

    }
  },
  data () {
    return {
      selectedItem: [],
      newsItemsComp: []

    }
  }

}
</script>

<style scoped>

</style>
