<template>
  <div>
    {{ currentPage }} / {{ pageCount }}
    <pdf
      src="https://learn-eu-central-1-prod-fleet01-xythos.content.blackboardcdn.com/608d78f160587/4894412?X-Blackboard-Expiration=1624492800000&X-Blackboard-Signature=shiKvnoD5TvRWSF2pIHHwjF9TpT%2FOI0JovrylOeE1MA%3D&X-Blackboard-Client-Id=329908&response-cache-control=private%2C%20max-age%3D21600&response-content-disposition=inline%3B%20filename%2A%3DUTF-8%27%27IP-Sample-Trailer-BB.pdf&response-content-type=application%2Fpdf&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20210623T180000Z&X-Amz-SignedHeaders=host&X-Amz-Expires=21600&X-Amz-Credential=AKIAZH6WM4PL5M5HI5WH%2F20210623%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Signature=61e241cf00a16b68e3a2054cbfa396159f614f3dfff0f249c26495d903108386"
      @num-pages="pageCount = $event"
      @page-loaded="currentPage = $event"
      style="width: 40%"
      :page="1"

    ></pdf>
    <button @click="nextPage">Next</button>
  </div>
</template>

<script>
import pdf from 'vue-pdf'

export default {
  name: 'pdfSefanja2',
  components: {
    pdf
  },
  data () {
    return {
      currentPage: 0,
      pageCount: 0
    }
  },
  methods: {
    nextPage () {
      const next = this.page() + 1
    }

  }

}
</script>

<style scoped>

</style>
