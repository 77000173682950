<template>
  <v-container
    fill-height fluid
    class="pa-0"
    id="cont"
  >
    <v-row
      no-gutters
    >
      <v-col cols="12" class="mx-auto text-center" id="mybody"
      >

        <h1>Get <span id="ready">Ready<br></span><span></span><span id="smart"><br></span></h1>

      </v-col>

      <v-col cols="12" class="mx-auto text-center" id="proto">
        <br>
        <p>Prototype student portal</p><br>
        <p class="mx-16 dim">We are 3 second year software engineering students at saxion.</p>
        <p class="mx-16 dim"> We made a new students portal which you can look here. Sample logins are on login
          page.</p>
        <p class="mx-16 dim">After looking around please give us some feedback. Thanks</p>

      </v-col>
    </v-row>


  </v-container>

</template>

<script>
export default {
  name: 'SmartWorldKeith',
  watch: {
    $route: {
      handler: (to, from) => {
        document.title = to.meta.title || 'Your Website'
      },
      immediate: true
    }
  }
}

</script>

<style scoped>

#cont {
  min-height: 70vh;
  background-color: #000;
}

.dim {
  color: #999999;
}

#proto {
  background-color: #000;
  color: white;
}

#mybody {
  padding: 0;
  margin: 0;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 30vh;
}

h1 {
  color: #484848;
  font-size: 50px;
  font-weight: bold;
  font-family: monospace;
  letter-spacing: 7px;
  cursor: pointer
}

h1 span {
  transition: .5s linear
}

h1:hover span:nth-child(1) {
  margin-right: 5px
}

h1:hover span:nth-child(2):after {
  content: "for a";
}

h1:hover span:nth-child(3):after {
  content: "Smart World";
}

h1:hover span:nth-child(2) {
  margin-left: 30px
}

h1:hover #smart, h1:hover #ready {
  color: white;
  text-shadow: 0 0 10px #fff,
  0 0 20px #fff,
  0 0 40px #fff;
}

/*made with ❤, by qpi65*/
/*https://codepen.io/qpi65/pen/LYNOXJO*/

</style>
