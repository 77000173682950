<template>

  <v-app>
    <v-card width="600" class="mx-auto mt-5" outlined shaped>
      <v-card-title class="pb-0">
        <h1 class="primary--text">Login</h1>
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-text-field
            label="Username"
            prepend-icon="mdi-account-circle"
          />
          <v-text-field
            :type="showPassword ? 'text' : 'password'"
            label="Password"
            prepend-icon="mdi-lock"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
          />
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn color="primary" :style="{left: '50%', transform:'translateX(-50%)'}">Login</v-btn>
      </v-card-actions>
      <v-card-actions>

        <v-btn color="primary" :style="{left: '50%', transform:'translateX(-50%)'}">Register</v-btn>
      </v-card-actions>
    </v-card>
  </v-app>
</template>

<script>
export default {
  name: 'Loginpagetuan'
}

</script>

<style scoped>

</style>
