<template>
  <div>
    <h1>This is a news page</h1>
    <p>
      <router-link to="/about">about link(v router)</router-link>
    </p>

    <p>
      <router-link to="/news">news list(v router)</router-link>
    </p>

    <p> this page address is {{ $route.params.id }}</p>

    <p> the news is: ->>>> {{ news[$route.params.id] }} </p>

    <router-view>
      <news-tuan></news-tuan>
    </router-view>

  </div>
</template>

<script>

export default {
  name: 'NewsInfo',
  components: {},
  data () {
    return {
      news: ['lon', 'corona news', 'student lotto', 'student housing']
    }
  }
}
</script>

<style scoped>

</style>
