<template>
  <div>

    <v-card
      class="mx-auto "
      color="primary"
      dark

    >
      <v-card-title>
        <v-icon
          large
          left
        >
          mdi-library
        </v-icon>
        <span class="text-h6 font-weight-light">{{ newsItem.title }} </span>
      </v-card-title>

      <v-card-text class="text-h5 font-weight-bold">
        {{ newsItem.content }}
      </v-card-text>

      <v-card-actions>
        <v-list-item class="grow">
          <v-list-item-avatar color="grey darken-3">
            <v-img
              class="elevation-6"
              alt=""
              src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
            ></v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>Saxion Teacher</v-list-item-title>
          </v-list-item-content>

          <v-row
            align="center"
            justify="end"
          >
            <v-icon class="mr-1">
              mdi-heart
            </v-icon>
            <span class="subheading mr-2">256</span>
            <span class="mr-1">·</span>
            <v-icon class="mr-1">
              mdi-share-variant
            </v-icon>
            <span class="subheading">45</span>
          </v-row>
        </v-list-item>
      </v-card-actions>
    </v-card>

  </div>
</template>

<script>
export default {
  name: 'NewsDetailsKeith',

  props: {
    newsItem: Object
  }
}
</script>

<style scoped>

</style>
