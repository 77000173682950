<template>
  <v-card

  >

    <v-sheet>

      <v-toolbar
        color="primary"
        dark

      >
        <v-toolbar-title>{{ $t(" Exam Schedule") }}</v-toolbar-title>

      </v-toolbar>

      <v-toolbar
        color="grey"
      >
        <v-btn
          @click="setToday"
        >
          Today
        </v-btn>
        <v-btn
          fab
          text
          small
          color="grey darken-2"
          @click="prev"
        >
          <v-icon small>
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <v-btn
          fab
          text
          small
          color="grey darken-2"
          @click="next"
        >
          <v-icon small>
            mdi-chevron-right
          </v-icon>
        </v-btn>
        <v-toolbar-title v-if="$refs.calendar">
          {{ $refs.calendar.title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
    </v-sheet>

    <v-sheet height="335">
      <v-calendar

        ref="calendar"
        v-model="focus"
        color="primary"
        type="category"
        category-show-all
        :categories="categories"
        :events="events"
        :event-color="getEventColor"
        @change="fetchEvents"
      >
        <template v-slot:day-body="{ date, week }">
          <div
            class="v-current-time"
            :class="{ first: date === week[0].date }"
            :style="{ top: nowY }"
          ></div>
        </template>
      </v-calendar>
    </v-sheet>

  </v-card>
</template>

<script>
import firebase from 'firebase/app'

export default {
  name: 'ScheduleSefanja',
  data: () => ({
    focus: '',
    events: [],
    colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
    names: ['exam 1', 'exam 2', 'exam 3', 'exam 4'],
    categories: ['Naga Baker'],
    value: '',
    ready: false,
    db: firebase.database()
  }),
  computed: {
    cal () {
      return this.ready ? this.$refs.calendar : null
    },
    nowY () {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px'
    }
  },
  mounted: function () {
    this.getNewData()

    this.$refs.calendar.checkChange()
    this.ready = true
    this.scrollToTime()
    this.updateTime()
  },
  methods: {
    getNewData () {
      const getSchedule = this.db.ref('exam_schedule')
      getSchedule.on('value', (snapshot) => {
        const data = snapshot.val()

        for (const key in data) {
          const schedule = data[key]
          const time = new Date(schedule.exam_times)
          const endTime = new Date(time.getTime() + 1800000)
          this.events.push({
            name: schedule.module_name,
            timed: true,
            start: time,
            end: endTime,
            color: 'green',
            category: 'Naga Baker'
          })
        }
      })
    },
    getEventColor (event) {
      return event.color
    },
    setToday () {
      this.focus = ''
    },
    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    },
    fetchEvents ({ start, end }) {
      const events = []

      const min = new Date(`${start.date}T14:00:00`)
      const max = new Date(`${end.date}T23:59:59`)
      const days = (max.getTime() - min.getTime()) / 86400000
      const eventCount = this.rnd(days, days + 20)

      for (let i = 0; i < eventCount; i++) {
        const allDay = this.rnd(0, 3) === 0
        const firstTimestamp = this.rnd(min.getTime(), max.getTime())
        const first = new Date(firstTimestamp - (firstTimestamp % 900000))
        const secondTimestamp = this.rnd(0, allDay ? 288 : 8) * 900000
        const second = new Date(first.getTime() + secondTimestamp)

        events.push({
          name: this.names[this.rnd(0, this.names.length - 1)],
          start: first,
          end: second,
          color: this.colors[this.rnd(0, this.colors.length - 1)],
          timed: !allDay,
          category: this.categories[this.rnd(0, this.categories.length - 1)]
        })
      }
    },
    rnd (a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
    getCurrentTime () {
      return this.cal ? this.cal.times.now.hour * 60 + this.cal.times.now.minute : 0
    },
    scrollToTime () {
      const time = this.getCurrentTime()
      const first = Math.max(0, time - (time % 30) - 30)

      this.cal.scrollToTime(first)
    },
    updateTime () {
      setInterval(() => this.cal.updateTimes(), 60 * 1000)
    }
  },
  rnd (a, b) {
    return Math.floor((b - a + 1) * Math.random()) + a
  }
}
</script>

<style scoped lang="scss">
.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: '';
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}
</style>
