<template>

  <div>
    <v-card

    >

      <v-tabs
        v-model="tab"
        align-with-title
        background-color="primary"
        center-active
        dark
      >
        <v-tabs-slider color="white"></v-tabs-slider>
        <!--keith repeat weeks-->
        <v-tab
          v-for="(topMenuItem, topMenuIndex) in newModulesObjectArray"
          :key="topMenuIndex"
        >
          {{ topMenuItem.topMenuName }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <!--       keith repeat menu from weeks object-->
        <v-tab-item
          v-for="(wholeContent, topMenuIndex) in newModulesObjectArray"
          :key="topMenuIndex"
        >

          <v-card>
            <v-tabs vertical>

              <v-tab v-for="(wholeContent,sideMenuIndex) in wholeContent.wholeContent" :key="sideMenuIndex">

                {{ wholeContent.name }}

              </v-tab>

              <v-tab-item v-for="{pageItems,sideMenuIndex} in wholeContent.wholeContent" :key="sideMenuIndex">
                <v-card
                  class="pa-2 mb-4"
                  outlined
                  elevation="2"
                  shaped
                  color="" v-for="(pageItem, index) in pageItems" :key="index"
                >
                  <v-card-title>{{ pageItem.title }}</v-card-title>
                </v-card>
              </v-tab-item>

            </v-tabs>
          </v-card>

        </v-tab-item>

      </v-tabs-items>

    </v-card>

  </div>
</template>

<script>

export default {
  name: 'Assignment4',
  data () {
    return {
      skill: 0,
      number: 20,
      tab: null,
      topMenuItems: [
        'Intro', 'Week 1', 'Week 2', 'Discussion', 'Extra'
      ],

      newModulesObjectArray:
        [{
          topMenuName: 'intro week',
          wholeContent: [
            {
              name: 'intro 1 intro week',
              pageItems:
                [
                  {
                    title: 'intro week task 1a',
                    subtitle: 'level 1',
                    content: 'copy past is golden'
                  },
                  {
                    title: 'intro week task 2a',
                    subtitle: 'level 1',
                    content: 'copy past is golden'
                  }
                ]
            },
            {
              name: 'setup 2 intro week',
              pageItems:
                [
                  {
                    title: 'intro week task 2a',
                    subtitle: 'level 2',
                    content: 'copy past is golden'
                  },
                  {
                    title: 'intro week task 2b',
                    subtitle: 'level 2',
                    content: 'copy past is golden'
                  }
                ]
            },
            {
              name: 'assignment 3 intro week',
              pageItems:
                [
                  {
                    title: 'intro week task 3a',
                    subtitle: 'level 2',
                    content: 'copy past is golden'
                  },
                  {
                    title: 'intro week task 3a',
                    subtitle: 'level 2',
                    content: 'copy past is golden'
                  }
                ]
            }]
        },
        {
          topMenuName: 'week 1',
          wholeContent: [{
            name: 'assignment 1 week 1',
            pageItems:
                [
                  {
                    title: 'task week 1 1a',
                    subtitle: 'level 2',
                    content: 'copy past is golden'
                  },
                  {
                    title: 'task week 1 1b',
                    subtitle: 'level 2',
                    content: 'copy past is golden'
                  }
                ]
          },
          {
            name: 'assignment 2 week 1',
            pageItems:
                  [
                    {
                      title: 'task week 1 2a',
                      subtitle: 'level 2',
                      content: 'copy past is golden'
                    },
                    {
                      title: 'task week 1 2b',
                      subtitle: 'level 2',
                      content: 'copy past is golden'
                    }
                  ]
          },
          {
            name: 'assignment 3 week 1',
            pageItems:
                  [
                    {
                      title: 'task week 1 3a',
                      subtitle: 'level 2',
                      content: 'copy past is golden'
                    },
                    {
                      title: 'task week 1 3b',
                      subtitle: 'level 2',
                      content: 'copy past is golden'
                    }
                  ]
          }]
        }
        ],
      pageItems: [],
      moduleData: [
        {
          module_code: 'SX12',
          topmenu: [
            {
              menu_name: 'intro',
              submenu: [
                {
                  submenu_name: 'introduction',
                  tasks: [
                    {
                      title: 'introduction',
                      subtitle: 'level 1',
                      content: 'welcome wij gaan beginnen'
                    },
                    {
                      title: 'course manual',
                      subtitle: 'welcome wij gaan beginnen',
                      content: 'copy past is golden'
                    }
                  ]
                }
              ]
            },
            {
              menu_name: 'week 1',
              submenu: [
                {
                  submenu_name: 'assignment',
                  tasks: [
                    {
                      title: 'How to install the Java Development Kit and IntelliJ IDEA Community Edition',
                      subtitle: 'Set up system',
                      content: "During the first lecture we will install the required software together, but in case you are wondering we have created a step-by-step guide on how to install the software needed for this course. Head over to https://www.oracle.com/nl/java/technologies/javase-downloads.htmland go download the Java SE 11 (LTS) installer for your specific operating system. You'll need to create an Oracle account and sign in to be able to download the installer. Head over to https://www.jetbrains.com/idea/download and get IntelliJ IDEA Community Edition for your specific operating system. Install the Java SE 11 development kit downloaded from step 1. Unfortunately, there is no \"easy\" way to check whether or not the installation was a success. If the installer exitedwithout errors, you should assume everything is up and running. If your installer crashes, please contact your teacher. Install IntelliJ IDEA Community Edition downloaded from step 2. Download the DemoProject.zip file (below) and extract it to somewhere you can easily find it. Open IntelliJ and select open and select the folder you extracted from the zipfile as discussed in step 5. Head over to \"1: Project\" (top left), open up \"Demo1\", open up \"src\" before double-clicking on \"Application\". You might need to wait before IntelliJ has indexed the Java Development Kit. If you see a progressbar in the bottom, wait a moment! Be patient. If everything worked out correctly, you should a green triangle that you can now click to run your program. If you can do so, your installation was succesful! Just to help out, we have created a video that shows all these steps and explains a little about what happens. Have a look at https://youtu.be/8Dp9jP56b4U."
                    },
                    {
                      title: 'Sandbox project',
                      subtitle: 'level 1',
                      content: 'Download the Sandbox project here. A Sandbox project does not contain exercises, but should be considered a playground for you to experiment upon!'
                    }
                  ]
                }
              ]
            }
          ]
        }
      ],
      weekPages: [
        {
          submenu: 'assignment 1',
          icon: 'mdi-help-circle',
          tasks: [
            {
              title: 'assignment 1a',
              subtitle: 'level 1',
              content: 'copy past is golden'
            }
          ]
        },
        {
          submenu: 'assignment 2',
          icon: 'mdi-book-open-blank-variant',
          tasks: [
            { title: 'assignment 2a', subtitle: 'level 2', text: 'copy paste is golden' },
            { title: 'assignment 2b', subtitle: 'level 2', text: 'copy paste is golden' }
          ]
        }
      ],

      links: [
        { title: 'Dashboard', icon: 'mdi-view-dashboard' },
        { title: 'Account', icon: 'mdi-account-box' },
        { title: 'Admin', icon: 'mdi-laptop' }
      ],
      e1: 1
    }
  },
  watch: {
    steps (val) {
      if (this.e1 > val) {
        this.e1 = val
      }
    }
  },
  computed: {},
  methods: {
    backStep (n) {
      if (n !== 1) {
        this.e1 = n - 1
      }
      if (this.skill >= 20) {
        this.skill = this.skill - 20
      }
    },
    nextStep (n) {
      this.e1 = n + 1
      if (this.skill < 100) {
        this.skill = this.skill + 20
      }
    },
    pushState () {
      const state = { page_id: 1, user_id: 5 }
      const title = ''
      const url = '/course-code/intro-to-programming/week-1/assignment-2'
      history.pushState(state, title, url)
    },
    generatePageData () {
      const topMenuItems = []
      const sideBarItems = []
      const pageItems = []

      this.moduleData.forEach(function callbackFn (modules) {
        modules.topmenu.forEach(function callbackFn (topMenu) {
          sideBarItems.push(topMenu.submenu.map(value => value.submenu_name))

          topMenuItems.push(topMenu.menu_name) // push top menu items ki
          topMenu.submenu.forEach(function callbackFn (submenu) {
            pageItems.push(submenu.tasks)
          })
        })
      })
    }

  },
  mounted: function () {

  }

}
</script>
