<template>
  <div>
    <v-card
      outlined

    >
      <v-toolbar
        color="primary"
        dark

      >

        <v-toolbar-title>{{ $t("Grades") }}</v-toolbar-title>

      </v-toolbar>

      <v-tabs
        v-model="tab"
        background-color="grey"
        dark
        height="30px"
        grow
      >
        <v-tab
          v-for="(topMenu,weekIndex) in gradeData"
          :key="weekIndex"
        >
          {{ topMenu.top_menu_name }}

        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">

        <!--bar-->
        <v-progress-linear
          v-model="skill"
          color="grey"
          height="25"
        >
          <template v-slot:default="{ value }">
            <strong>{{ Math.ceil(value) }}%</strong>
          </template>
        </v-progress-linear>

        <v-tab-item
          v-for="(subjects,index) in gradeData"
          :key="index"
        >

          <v-card flat>

            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">
                    Subject
                  </th>
                  <th class="text-left">
                    Grade
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr
                  v-for="subject in subjects.submenu_items"
                  :key="subject.name"
                >
                  <td>{{ subject.name }}</td>
                  <td>{{ subject.grade }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>

          </v-card>

        </v-tab-item>
      </v-tabs-items>

    </v-card>
  </div>
</template>

<script>
import { fbGetAllTree } from '@/plugins/dbOperations'

export default {
  name: 'GradesTest',
  data () {
    return {

      skill: 20,

      grades: [
        {
          subject: 'Introduction to programming',
          grade: 8
        },
        {
          subject: 'Website Technology',
          grade: 6
        },
        {
          subject: 'Concurrency',
          grade: 9
        }
      ],
      tab: null,
      gradeData: [{
        top_menu_name: 'Y1',
        submenu_items: [{
          name: 'Curiosity11',
          grade: '1'
        },
        {
          name: 'Databases',
          grade: '2'
        },
        {
          name: 'Infrastructure',
          grade: '6'
        },
        {
          name: 'Imagine 2030',
          grade: '8'
        },
        {
          name: 'OOP',
          grade: '10'
        },
        {
          name: 'Computer Networks',
          grade: '7'
        }
        ]
      },
      {
        top_menu_name: 'Y2',
        submenu_items: [{
          name: 'Concurrency',
          grade: '3'
        },
        {
          name: 'Web Technology',
          grade: '2'
        },
        {
          name: 'C++',
          grade: '10'
        },
        {
          name: 'Algorithms',
          grade: '9'
        },
        {
          name: 'IT and Law',
          grade: '6'
        },
        {
          name: 'Dev Tools',
          grade: '6'
        }
        ]
      },
      {
        top_menu_name: 'Y3',
        submenu_items: [{
          name: 'Internship Bachelor',
          grade: '1'
        },
        {
          name: 'Minor',
          grade: '2'
        },
        {
          name: 'Advanced Applications',
          grade: '7'
        }
        ]
      },
      {
        top_menu_name: 'Y4',
        submenu_items: [{
          name: 'Internet Of Things',
          grade: '1'
        },
        {
          name: 'Graduation Bachelor',
          grade: '8'
        }
        ]
      }
      ]
    }
  },
  methods: {
    getData () {
      fetch(
        'http://localhost:3000/sefanjagrades',
        {
          method: 'get'
        }
      )
        .then((response) => {
          return response.json()
        })
        .then((jsonData) => {
          this.grades = jsonData
        })
    },
    async getNewData () {
      const getGrade = await fbGetAllTree('grades')
      this.gradeData = getGrade
    }
  },
  mounted: function () {
    this.getNewData()
  }
}

</script>

<style scoped>

</style>
