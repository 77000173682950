<template>

  <div>
    <v-toolbar dark color="primary" flat height="40">
      <v-card-title class="body-1">{{ moduleData.module_name }}</v-card-title>
      <v-spacer></v-spacer>
      <v-card-subtitle dark class="body-2">{{ moduleData.module_code }}</v-card-subtitle>
    </v-toolbar>
    <v-card class="my-1 mx-auto" max-width="90%">

      <v-tabs
        v-model="topTabs"
        align-with-title
        background-color="primary"
        center-active
        dark>
        <v-tabs-slider color="white"></v-tabs-slider>
        <!--keith repeat weeks-->
        <v-tab
          v-for="(topMenu,weekIndex) in moduleData.weekPages"
          :key="weekIndex">
          {{ $t(topMenu.top_menu_name) }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="topTabs">
        <!--       keith repeat menu from weeks object-->
        <v-tab-item
          v-for="(weekPage,weekIndex2) in moduleData.weekPages"
          :key="weekIndex2">

          <v-card>
            <v-tabs vertical>
              <!--          keith    repeat this weeks left menu items-->
              <v-tab v-for="(singlePage,sideMenuIndex) in weekPage.submenu" :key="sideMenuIndex">
                <v-icon left>
                  {{ singlePage.icon }}
                </v-icon>
                {{ $t(singlePage.name) }}
              </v-tab>

              <!--         keith     repeat selected menu content-->
              <v-tab-item v-for="(SubmenuItems,sideMenuItemsIndex) in weekPage.submenu_items" :key="sideMenuItemsIndex">

                <div v-for="(task,taskIndex) in SubmenuItems.tasks" :key="taskIndex">
                  <v-card
                    class="pa-2 ma-4 my-4"
                    outlined
                    elevation="2"

                  >

                    <v-toolbar color="primary" flat>
                      <v-card-title class="white--text">{{ $t(capitalize(task.title)) }}</v-card-title>
                      <v-spacer></v-spacer>
                      <v-card-subtitle class="white--text">{{ $t(capitalize(task.subtitle)) }}</v-card-subtitle>
                    </v-toolbar>
                    <VideoPlayerComponentKeith class="pa-5" v-if="task.videoid"
                                               v-bind:videoId="task.videoid"></VideoPlayerComponentKeith>

                    <!--                  <template><pdf src="./path/to/static/relativity.pdf"></pdf></template>-->

                    <pdf
                      v-if="task.src"
                      v-bind:src="task.src"

                      style="width: 100%"
                    ></pdf>

                    <v-card-text>
                      <span v-if="task.content" v-html="task.content"> {{ capitalize(task.content) }} </span>
                    </v-card-text>
                    <v-card-actions></v-card-actions>
                  </v-card>

                  <!--                    questions-->
                  <v-toolbar class=" mx-4" dark
                             color="primary">
                    <v-toolbar-title>
                      Conversation
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-icon>
                      mdi-comment-text-outline
                    </v-icon>
                  </v-toolbar>

                  <v-card class="mx-4 mb-4  overflow-y-auto"
                          outlined height="300">

                    <!--                    start of one comment-->
                    <v-container>
                      <v-row v-for="(question,questionIndex) in task.questions" v-bind:key="questionIndex">
                        <v-col cols="8" v-bind:offset="setCommentBoxCol(question)">
                          <v-card shaped
                                  elevation="2"
                                  width="100%"

                                  v-bind:color="setCommentBoxColor(question)"
                          >
                            <v-container class="py-1">
                              <v-row>
                                <v-col class="text-h6" cols="6">
                                  {{ question.student_name }}
                                </v-col>
                                <v-col class="body-2 text-right " cols="6">
                                  {{ setAgo(question.dateTime) }}
                                </v-col>
                              </v-row>
                            </v-container>

                            <v-card-text class="subtitle-2 py-0">{{ question.comment }}</v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-container>

                  </v-card>
                  <v-text-field class="pa-4"
                                append-outer-icon="mdi-send"
                                filled
                                clear-icon="mdi-close-circle"
                                clearable
                                v-model="comment"
                                label="Comment or ask question but don't be a chav"
                                type="text"
                                v-bind:key="taskIndex"
                                @click:append-outer="sendComment(comment, weekIndex2, sideMenuItemsIndex, taskIndex)"
                  ></v-text-field>
                  <v-alert type="warning" v-if="blankComment" max-width="50%" class="mx-auto">{{ blankComment }}
                  </v-alert>

                </div>

              </v-tab-item>

              <!--              assignment dealership keith-->
              <v-tab-item>
                <SubmitAssignmentKeith v-bind:module_code="moduleData.module_code"
                                       v-model:student_id="getStudentId"></SubmitAssignmentKeith>

                <DownloadAssignmentKeith v-bind:module_code="moduleData.module_code"></DownloadAssignmentKeith>
              </v-tab-item>

            </v-tabs>
          </v-card>

        </v-tab-item>

      </v-tabs-items>

    </v-card>

  </div>
</template>

<script>

import { fbAddCommentToModule } from '../plugins/dbOperations'
import VideoPlayerComponentKeith from '@/components/VideoPlayerComponentKeith'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import moment from 'moment'
import introToProgramming from '../data/module_data_introtoprogram'
import pdf from 'vue-pdf'
import SubmitAssignmentKeith from '@/components/SubmitAssignmentKeith'
import DownloadAssignmentKeith from '@/components/DownloadAssignmentKeith'

export default {

  components: { DownloadAssignmentKeith, SubmitAssignmentKeith, VideoPlayerComponentKeith, pdf },
  name: 'Assignment5',
  props: {
    moduleData: {
      type: [Object, String, Array],
      default: function () {
        return introToProgramming
      }
    }
  },

  data () {
    return {
      blankComment: null,
      comment: null,
      sideTabs: null,
      topTabs: null,
      question: {
        student_name: 'sef',
        student_id: '458223',
        comment: 'i dont get it.',
        dateTime: 1624470795000
      }

    }
  },
  methods: {
    ...mapMutations(['saveUser']),
    ...mapActions(['']),
    ...mapGetters(['getUser']),

    getStudentId () {
      const stid = this.getUser().student_id
      return stid
    },

    setCommentBoxCol (comment) {
      if (this.getUser().student_id === comment.student_id) {
        return 4
      } else {
        return 0
      }
    },

    setCommentBoxColor (comment) {
      if (this.getUser().student_id === comment.student_id) {
        return 'amber lighten-4'
      } else {
        return ''
      }
    },

    setAgo (timestamp) {
      return moment(timestamp).fromNow()
    },
    sendComment (comment, top, side, task) {
      // clear warning
      this.blankComment = null

      // no message
      if (!comment || comment.trim() === '') {
        this.blankComment = 'whos going to write message bruv!'
        return
      }

      // create comment
      const newComment = {}
      newComment.student_name = this.getUser().first_name + ' ' + this.getUser().last_name.slice(0, 1) + '.'
      newComment.student_id = this.getUser().student_id.toString()
      newComment.comment = comment
      newComment.dateTime = Date.now()

      // check if comment property exist
      if (!this.moduleData.weekPages[top].submenu_items[side].tasks[task].hasOwnProperty('questions')) {
        this.moduleData.weekPages[top].submenu_items[side].tasks[task].questions = []
      }
      this.moduleData.weekPages[top].submenu_items[side].tasks[task].questions.push(newComment)

      // clear text box
      this.comment = ''

      // save to db
      fbAddCommentToModule(this.moduleData.module_code, top, side, task, newComment)
    },
    pushState () {
      const state = { page_id: 1, user_id: 5 }
      const title = ''
      const url = '/course-code/intro-to-programming/week-1/assignment-2'
      history.pushState(state, title, url)
    },

    capitalize (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },

    async getLink () {
      await this.src.promise.then(pdf => {
        this.numPages = pdf.numPages
      })
    }

  }

}
</script>
