<template>
  <div>

    <v-card
    >
      <v-toolbar
        color="primary"
        dark
      >
        <v-toolbar-title>{{ $t("News") }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon>
          <v-icon>mdi-newspaper</v-icon>
        </v-btn>
      </v-toolbar>

      <v-img max-width="80%" max-height="" class="mx-auto"
             src="https://image.focuspoints.io/saxion-enschede-student-reading-a-newspaper.png?_jwt=eyJhbGciOiJIUzUxMiJ9.eyJmb2N1c1BvaW50WSI6MC4xOCwiZm9jdXNQb2ludFgiOi0wLjAyLCJ3aWR0aCI6MTIwMCwiaXNzIjoic2F4aW9uIiwiYWN0aW9uIjoidHJhbnNmb3JtIiwidXJsIjoiaHR0cHM6Ly93d3cuc2F4aW9uLmVkdS9iaW5hcmllcy9jb250ZW50L2dhbGxlcnkvc2F4aW9uLWVkdS9pbWFnZXMvc2F4aW9uLWVuc2NoZWRlLXN0dWRlbnQtcmVhZGluZy1hLW5ld3NwYXBlci5wbmc_dHM9MTU0MzE2NTgyNDY2NyIsImhlaWdodCI6NjMwfQ.Y_o4PwbURi48-VYMZuNsjkR3r0RXLdn3V4_Xu6L74OHHPkaPWeHOsG_NopdCVZWUbGTWXpYIENAzFkZG4oc0pw"></v-img>
      <v-card-title>
        <router-link v-bind:to="'news/'+  newsItemsComp[0].slug "> {{ newsItemsComp[0].title }}</router-link>

      </v-card-title>

      <v-card-subtitle>More News</v-card-subtitle>

      <v-list height="150" class="overflow-auto">

        <v-list-item-group
          v-model="selectedItem"
          color="primary"
        >
          <v-list-item
            class="text-h7" v-for="(newsItem,index) in newsItemsComp" v-bind:key="index"
          >
            <v-img max-width="75" max-height="75"
                   src="https://www.saxion.nl/binaries/content/assets/over-saxion/organisatie/toolkit/lg_saxion_rgb.jpg"></v-img>
            <router-link class="ml-5" v-bind:to="'news/'+  newsItem.slug "> {{ newsItem.title }}</router-link>

          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
  </div>
</template>

<script>

export default {
  name: 'NewsListTuan',
  props: {
    newsItems: {
      type: Array

    }
  },
  data () {
    return {
      selectedItem: [],
      newsItemsComp: [
        {
          title: 'Student job opportunity for current students of Saxion',
          slug: 'news-1',
          content: 'This team will be the international face of Saxion and will be welcoming, accompanying and helping potential international students during numerous off- and online activities during their study choice and the run-up to their study at Saxion. Also the ambassadors will contribute to the content creation for our international communication channels, both by writing testimonials and starring in videos about their experiences and the international student life at Saxion. We need ambassadors from all of our English-taught study programmes. To become an international student ambassador, you need to be…'
        },
        {
          title: 'Re-enroll as soon as possible after 2 June',
          slug: 'news-2',
          content: 'Will you resume your studies after the summer? Re-enroll through studielink.nl as soon as possible after 2 June, so that you can continue studying at the start of the new academic year, online or in-person, without any problems.'
        },
        {
          title: 'Deventer temporarily uses an extra teaching location',
          slug: 'news-3',
          content: 'If you study at Saxion in Deventer, you may in the coming weeks have some lessons at De Leeuwenbrug. You will find this location within walking distance of Saxion, near the train station. The entrance to Saxion is indicated at the front of De Leeuwenbrug.'
        },
        {
          title: 'Saxion Introduction Days 2021: Saxion Campsite almost open',
          slug: 'news-4',
          content: "Thinking in terms of what's possible, that is the challenge we still face this year. With the end of the pandemic in sight, the outlook for after the summer is becoming clearer. But how do we ensure that we can give new students a warm welcome as well as organising a safe Introduction? Tricky, but not impossible! That's why we are proud to share that on Tuesday, 24th August the online 'Saxion Campsite' goes live for our new students! For three days this online platform will form the basis for all activities during the Introduction Days. We will supplement this with small-scale, in-person introductions, set out as hotspots in the Saxion cities. Students can compile their own programme together with classmates. After the positive experiences of last year, we are again aiming to have one Saxion Introduction, with the introduction to degree programmes being part of the introduction programme. All of this is starting to take shape, so above all check the introduction page to stay informed. Here you will find for example, a fun introduction to this year's student organisers."
        }
      ]

    }
  }

}
</script>

<style scoped>

</style>
